import { observer } from "mobx-react";
import React, { useContext } from "react";
import { __RouterContext } from "react-router";
import { MANAGE_USERS } from "../../../routes";
import { USER_ROLE } from "../../../server-api/model";
import { appContext } from "../../../state/appState";
import { downloadsContext } from "../../../state/downloadsState";
import { modalContext } from "../../../state/modalState";
import { profileContext } from "../../../state/profileState";
import { reportsContext } from "../../../state/reportsState";
import { Button } from "../../common/button/Button";
import { Bullet } from "../../common/icons";
import { CustomScrollbars } from "../../common/scrollbars/Scrollbars";
import "./Sidebar.css";

export const Sidebar = observer(() => {
  const appState = useContext(appContext);
  const modalState = useContext(modalContext);
  const routerState = useContext(__RouterContext);
  const profileState = useContext(profileContext);
  const downloadsState = useContext(downloadsContext);
  const reportsState = useContext(reportsContext);
  //const [targetHeight, setTargetHeight] = useState(0);

  if (!reportsState.initialized) {
    reportsState.initialize();
  }

  const prepareReportModal = (
    e: React.MouseEvent<HTMLSpanElement>,
    reportName: string
  ) => {
    e.preventDefault();
    appState.toggleBigSpin(true);
    reportsState.selectReport(reportName).then(() => {
      appState.toggleBigSpin(false);
      modalState.toggleModal("reportsModal", true);
    });
  };

  const prepareDownloadModal = () => {
    appState.toggleBigSpin(true);
    downloadsState
      .initialize()
      .then(() => {
        modalState.toggleModal("download", true);
        appState.toggleBigSpin(false);
      })
      .catch(() => {
        appState.toggleBigSpin(false);
      });
  };

  return (
    <div className="Sidebar">
      <aside className={"section reports-section"} aria-labelledby="reportsSectionLabel">
        <div className="reports-scrollbox-wrapper">
          {reportsState.loadingReports ? (
            <div className="PlaceholderReports">
              <h2 id="reportsSectionLabel">Reports</h2>
              <ul>
                <li>
                  <p>&nbsp;</p>
                </li>
                <li>
                  <p>&nbsp;</p>
                </li>
                <li>
                  <p>&nbsp;</p>
                </li>
                <li>
                  <p>&nbsp;</p>
                </li>
                <li>
                  <p>&nbsp;</p>
                </li>
                <li>
                  <p>&nbsp;</p>
                </li>
                <li>
                  <p>&nbsp;</p>
                </li>
              </ul>
            </div>
          ) : (
            <CustomScrollbars
              style={{}}
              shadow={true}
              scrollbarStyle={{ right: "-16px" }}
              disable={window.innerWidth < 768}
            >
              <h2 id="reportsSectionLabel">Reports</h2>
              <ul>
                {Object.keys(reportsState.reports || {})
                  .sort((a, b) => {
                    return a.localeCompare(b);
                  })
                  .map((reportName, index) => {
                    return (
                      <li key={"key-" + reportName}>
                        <Bullet />
                        <button
                          className="btn-linkified"
                          onClick={e => prepareReportModal(e, reportName)}
                        >
                          {reportName}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </CustomScrollbars>
          )}
        </div>
      </aside>

      {profileState.portalRole === USER_ROLE.MANAGER || profileState.portalRole === USER_ROLE.MANAGER_NO_UPDATE ? (
        <aside className="section" aria-labelledby="filesSectionLabel">
          <h2 id="filesSectionLabel">Files</h2>
          <Button
            onClick={() => modalState.toggleModal("uploadCases", true)}
            className="btn"
          >
            Upload a file
          </Button>
          <Button onClick={prepareDownloadModal} className="btn">
            Download files
          </Button>
        </aside>
      ) : null}
      {profileState.isAdmin ? (
        <aside className="section" aria-labelledby="usersSectionLabel">
          <h2 id="usersSectionLabel">Users</h2>
          <Button
            onClick={() => {
              routerState.history.push(MANAGE_USERS);
            }}
            className="btn"
          >
            Manage users
          </Button>
        </aside>
      ) : null}
    </div>
  );
});
