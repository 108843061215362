import { USER_ROLE } from "server-api/model";
import { ModalState } from "../../state/modalState";

export class Actions {
  constructor(modalState: ModalState) {
    this.modalState = modalState;
  }
  modalState: ModalState;

  getActions = () => {
    return [
      {
        scope: { type: "both", single: false, condition: "placeOnHold" },
        action: () => {
          this.modalState.toggleModal("placeOnHold", true);
        },
        name: "Place on hold",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER
        ]
      },
      {
        scope: { type: "both", single: false, condition: "takeOffHold" },
        action: () => {
          this.modalState.toggleModal("takeOffHold", true);
        },
        name: "Take off hold",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER
        ]
      },
      {
        scope: { type: "both", single: false, condition: "requestReturn" },
        action: () => {
          this.modalState.toggleModal("requestReturn", true);
        },
        name: "Request return",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER
        ]
      },
      {
        scope: { type: "case", single: true, condition: "liveCase" },
        action: () => {
          this.modalState.toggleModal("changeDebt", true);
        },
        name: "Change debt amount",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER
        ]
      },
      {
        scope: { type: "case", single: true, condition: "liveCase" },
        action: () => {
          this.modalState.toggleModal("addPayment", true);
        },
        name: "Add direct payment",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER
        ]
      },
      {
        scope: { type: "both", single: false, condition: "fileUpload" },
        action: () => {
          this.modalState.toggleModal("uploadFile", true);
        },
        name: "Upload file",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE
        ]
      },
      {
        scope: { type: "both", single: false, condition: "contactDetails" },
        action: () => {
          this.modalState.toggleModal("addContact", true);
        },
        name: "Add contact details",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE
        ]
      },
      {
        scope: { type: "both", single: false, condition: "note" },
        action: () => {
          this.modalState.toggleModal("addNote", true);
        },
        name: "Add note",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE
        ]
      },
      {
        scope: { type: "both", single: false, condition: "message" },
        action: () => {
          this.modalState.toggleModal("messageJBW", true);
        },
        name: "Message " + window.COMPANY_LABEL,
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE,
          USER_ROLE.VIEW_ONLY
        ]
      }
    ];
  };

  getDebtorActions = () => {
    return [
      {
        scope: { type: "both", single: false, condition: "fileUpload" },
        action: () => {
          this.modalState.toggleModal("uploadFile", true);
        },
        name: "Upload file",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE
        ]
      },
      {
        scope: { type: "both", single: false, condition: "contactDetails" },
        action: () => {
          this.modalState.toggleModal("addContact", true);
        },
        name: "Add contact details",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE
        ]
      },
      {
        scope: { type: "both", single: false, condition: "note" },
        action: () => {
          this.modalState.toggleModal("addNote", true);
        },
        name: "Add note",
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE
        ]
      },
      {
        scope: { type: "both", single: false, condition: "message" },
        action: () => {
          this.modalState.toggleModal("messageJBW", true);
        },
        name: "Message " + window.COMPANY_LABEL,
        roles: [
          USER_ROLE.UPDATE,
          USER_ROLE.MANAGER,
          USER_ROLE.MANAGER_NO_UPDATE,
          USER_ROLE.VIEW_ONLY
        ]
      }
    ];
  };
}
