import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext, useState, useEffect } from "react";
import { Case } from "../../../server-api/model";
import { actionsContext } from "../../../state/actionsState";
import { toastSubject } from "../../../state/rxjs";
import { formatNumber } from "../../../utils/formatNumber";
import { Input } from "../../common/input/Input";
import { InputSelect } from "../../common/input/InputSelect";
import { validator } from "../../common/input/validator";
import { PopupEvent } from "../../common/popup/Popup";
import { ModalProps } from "../Modals";
import { SelectInfo } from "./selectinfo/SelectInfo";
import { ActionSubmit } from "./submit/ActionSubmit";
import { getCaseStatement } from "../../../server-api/api";
import { Spinner } from "../../common/spinner/Spinner";
import { useKeys } from "../../../utils/hooks";
import validate from "validate.js";
import {InputDate} from "../../common/input/InputDate";
import {InputTime} from "../../common/input/InputTime";
import "./AddPayment.css";

export const AddPayment: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);

    const [outstandingBalance, setOutstandingBalance] = useState<number | null>(
      null
    );

    const selectedCase: Case = AS.selectedCases.keys().next().value;

    useEffect(() => {
      if (outstandingBalance === null) {
        getCaseStatement(selectedCase.oneStepId.toString()).then((res) => {
          if (res.data) {
            setOutstandingBalance(res.data.debtBalanceWithSettlementSubTotal + res.data.interestBalanceWithSettlementSubTotal);
          } else {
            toastSubject.next("FAIL");
          }
        });
      }
    }, [outstandingBalance, selectedCase]);

    const formName = "AddPayment";

    const changeType = action((value: string) => {
      AS.paymentType = value;
    });

    const changeAmount = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.paymentAmount = e.currentTarget.value;
    });

    const submit = useCallback(() => {
      if (AS.submitting) {
        return;
      }
      validator
        .validate(formName, true)
        .then(() => {
          AS.submitAddPayment()
            .then(() => {
              toastSubject.next(PopupEvent.ADD_PAYMENT_SUCCESS);
              closeModal();
            })
            .catch((err) => {
              toastSubject.next(err.message);
            });
        })
        .catch(() => 0);
    }, [AS, closeModal]);

    const formatAmount = () => {
      let amount = AS.paymentAmount;

      if (amount && amount.length && amount.indexOf(".") === -1) {
        amount += ".00";
      }
      if (amount && amount.length && amount.indexOf(".") > -1) {
        amount = amount.replace(/^\.\d+$/g, "0" + amount);
        amount = amount.replace(/^\d+\.\d{1}$/g, amount + "0");
        amount = amount.replace(/^\d+\.$/g, amount + "00");
        amount = amount.replace(/^\.$/g, "0.00");
      }
      runInAction(() => {
        AS.paymentAmount = amount;
        const input = document.querySelector("#debt-adjust-amount");
        if (input) {
          (input as any).rawValue = amount || "";
        }
      });
    };

    useKeys(["Enter"], [formatAmount], undefined, true);

    if (!selectedCase) {
      return null;
    }

    return (
      <>
        <div>
          <SelectInfo />
          <h3>Payment details</h3>
          <p>
            <span className="p-label">Outstanding balance:</span>
            <span className="p-text outstanding-balance">
              {outstandingBalance !== null ? (
                "£" + formatNumber(outstandingBalance || 0)
              ) : (
                <Spinner
                  style={{ height: "16px", verticalAlign: "baseline" }}
                  small={true}
                />
              )}
            </span>
          </p>
          <Input
            formName={formName}
            placeholder="£0.00"
            options={{
              rawValueTrimPrefix: true,
              numeral: true,
              numeralPositiveOnly: true,
              numeralThousandsGroupStyle: "thousand",
              prefix: "£",
              numeralDecimalScale: 2,
              initValue: "0.00",
              noImmediatePrefix: true,
            }}
            validateFunc={() => {
              return validate.single(AS.paymentAmount, {
                presence: { allowEmpty: false },
                numericality: {
                  strict: true,
                  greaterThan: 0,
                  notGreaterThan: "A positive amount is required",
                },
              });
            }}
            label="Payment amount"
            value={AS.paymentAmount}
            onFocusOut={formatAmount}
            name="debt-adjust-amount"
            onChange={changeAmount}
          />
          <InputSelect
            formName={formName}
            options={AS.paymentCategories}
            label="Payment type"
            placeholder="Select type"
            name="debt-adjust-type"
            selectedThing={AS.paymentType}
            onChange={changeType}
            validateFunc={() =>
              validate.single(AS.paymentType, {
                presence: { allowEmpty: false, message: "Type is required" },
              })
            }
          />
            <div className="date-time-box">
                <InputDate
                    formName={formName}
                    name="payment-date"
                    label="Date received"
                    onChange={(date?: number) => {
                        AS.receiveDate = date;
                    }}
                    value={AS.receiveDate}
                    validateFunc={() =>
                        validate.single(AS.receiveDate, {
                            presence: {allowEmpty: false, message: "Date is required"},
                        })}
                />
                <InputTime
                    formName={formName}
                    name="payment-time"
                    label="Time"
                    onChange={(time?: number) => {
                        AS.receiveTime = time;
                    }}
                    value={AS.receiveTime}
                    validateFunc={() =>
                        validate.single(AS.receiveTime, {
                            presence: {allowEmpty: false, message: "Time is required"},
                        })}
                />
            </div>

          {/*           <h2>Comments</h2>
          <TextField
            formName={formName}
            placeholder="Add a comment"
            name="comments"
            onChange={changeComment}
            value={AS.paymentComments}
            className="wide"
          /> */}
        </div>
        <ActionSubmit
          submitting={AS.submitting}
          cancel={closeModal}
          submit={submit}
          submitText="Add direct payment"
        />
      </>
    );
  }
);
