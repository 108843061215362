import {action, observable, runInAction} from 'mobx';
import {debounceTime} from 'rxjs/operators';
import {DEBOUNCE_AFTER_ACTION_DURATION,} from '../../../../config';
import {getCaseSettlements,} from '../../../../server-api/api';
import {Settlement} from '../../../../server-api/model';
import {actionSuccessEvent, toastSubject} from '../../../../state/rxjs';

export class CaseSettlementsState {
    constructor(oneStepId: number) {
        actionSuccessEvent
            .pipe(debounceTime(DEBOUNCE_AFTER_ACTION_DURATION))
            .subscribe(() => {
                this.getSettlements({withLoading: false});
            });
        this.oneStepId = oneStepId;
        this.getSettlements();
    }

    oneStepId: number;
    @observable
    settlements: Settlement[] = [];
    @observable
    loadingSettlements = true;

    @action
    getSettlements = async ({withLoading = true} = {}) => {
        try {
            if (withLoading) {
                this.loadingSettlements = true;
            }
            const [settlements] = await Promise.all([
                getCaseSettlements(this.oneStepId.toString()),
            ]);
            if (settlements.error) {
                throw Error(settlements.error);
            }
            runInAction(() => {
                this.settlements = [...settlements.data];
            });
        } catch (err: any) {
            toastSubject.next(err.message);
        } finally {
            if (withLoading) {
                this.loadingSettlements = false;
            }
        }
    };
}
