import React from 'react';
import { capitalize } from 'validate.js';
import { DebtorDto } from '../../../server-api/model';
import { formatNumber } from '../../../utils/formatNumber';
import { DebtorScore } from '../../modals/DebtorScore/DebtorScore';
import { modalToggleSubject } from '../../modals/ModalsV2';
import './DebtorStats.css';
import { appState } from 'state/appState';

interface Props {
  debtor: DebtorDto;
  showAddress?: boolean;
}

const openScoreModal = (debtor: DebtorDto) => {
  modalToggleSubject.next({
    id: 'DebtorScore',
    component: DebtorScore,
    open: true,
    title: 'Customer score details',
    props: { explanation: debtor.debtorScore?.explanation },
  });
};

const getName = (debtor: DebtorDto) => {
  const { name, surname, tradingName } = debtor.contractor;
  let debtorName = '';
  if (surname) {
    debtorName += surname;
  }
  if (name) {
    debtorName += (surname ? ', ' : '') + name;
  }
  if (debtor.isCompany && tradingName) {
    if (!name && !surname) {
      debtorName = tradingName;
    }
  }
  return debtorName;
}

const StatsCompactTable = ({ debtor, showAddress }: Props) => {
  return (
    <table aria-label={getName(debtor)} className='DebtorStatsCompact'>
      <tbody>
        {showAddress ? (
          <tr>
            <th scope='row'>Address</th>
            <td>
              {(debtor.mainAddress && debtor.mainAddress.line1) || '-'}
              <br />
              {debtor.mainAddress && debtor.mainAddress.postcode}
            </td>
          </tr>
        ) : null}
        {appState.portalConfig.hideCustomerScore ? null : (
          <tr>
            <th scope='row'>
              {debtor.debtorScore ? (
                <button
                  className='btn-linkified'
                  onClick={() => openScoreModal(debtor)}
                >
                  Score
                </button>
              ) : ("Score")}
            </th>
            <td>
              {debtor.debtorScore &&
                debtor.debtorScore.scoreLevel &&
                debtor.debtorScore.scoreLevel[0].toUpperCase() +
                  debtor.debtorScore.scoreLevel.substring(1).toLowerCase()}
            </td>
          </tr>
        )}
        <tr>
          <th scope='row'>Vulnerable</th>
          <td>
            {debtor.isVulnerable ? (
              <div className='highlight red'>Yes</div>
            ) : (
              <div className='highlight gray'>No</div>
            )}
          </td>
        </tr>
        <tr>
          <th scope='row'>Warning</th>
          <td>
            {debtor.warning ? (
              <div className='highlight red'>Yes</div>
            ) : (
              <div className='highlight gray'>No</div>
            )}
          </td>
        </tr>
        <tr>
          <th scope='row'>Arrangement Status</th>
          <td>
            {debtor.arrangement ? capitalize(debtor.arrangement.status) : ''}
          </td>
        </tr>
        <tr>
          <th scope='row' className='stat-balance-cell'>Total Balance</th>
          <td className='stat-balance-cell'>£{formatNumber(debtor.debtBalance)}</td>
        </tr>
      </tbody>
    </table>
  );
}

const StatsTable = ({ debtor, showAddress }: Props) => {
  return (
    <table aria-label={getName(debtor)} className='DebtorStats'>
      <thead>
        <tr>
          {showAddress ? (
            <th className='stat-address-cell'>
              Address
            </th>
          ) : null}
          {appState.portalConfig.hideCustomerScore ? null : (
            <th className='stat-score-cell'>
              {debtor.debtorScore ? (
                <button
                  className='btn-linkified'
                  onClick={() => openScoreModal(debtor)}
                >
                  Score
                </button>
              ) : ("Score")}
            </th>
          )}
          <th className='stat-vulnerability-cell'>
            Vulnerable
          </th>
          <th className='stat-warning-cell'>
            Warning
          </th>
          <th className='stat-arrangement-cell'>
            Arrangement Status
          </th>
          <th className='stat-balance-cell'>
            Total Balance
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {showAddress ? (
            <td className='stat-address-cell'>
              {(debtor.mainAddress && debtor.mainAddress.line1) || '-'}
              <br />
              {debtor.mainAddress && debtor.mainAddress.postcode}
            </td>
          ) : null}
          {appState.portalConfig.hideCustomerScore ? null : (
            <td className='stat-score-cell'>
              {debtor.debtorScore &&
                debtor.debtorScore.scoreLevel &&
                debtor.debtorScore.scoreLevel[0].toUpperCase() +
                debtor.debtorScore.scoreLevel.substring(1).toLowerCase()}
            </td>
          )}
          <td className='stat-vulnerability-cell'>
            {debtor.isVulnerable ? (
              <div className='highlight red'>Yes</div>
            ) : (
              <div className='highlight gray'>No</div>
            )}
          </td>
          <td className='stat-warning-cell'>
            {debtor.warning ? (
              <div className='highlight red'>Yes</div>
            ) : (
              <div className='highlight gray'>No</div>
            )}
          </td>
          <td className='stat-arrangement-cell'>
            {debtor.arrangement ? capitalize(debtor.arrangement.status) : ''}
          </td>
          <td className='stat-balance-cell'>
            £{formatNumber(debtor.debtBalance)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export const DebtorStats = ({ debtor, showAddress }: Props) => {
  return (
    <div className='DebtorStatsWrapper'>
      <StatsTable debtor={debtor} showAddress={showAddress} />
      <StatsCompactTable debtor={debtor} showAddress={showAddress} />
    </div>
  );
};
