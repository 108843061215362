import { decorate, observable } from 'mobx';
import React, { createContext } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PopupEvent } from '../components/common/popup/Popup';
import { SHORT_DEBOUNCE_DURATION } from '../config';
import {
  createUser,
  getUsers,
  resetUserPassword,
  updateUser,
  updateUserRole,
  updateUserStatus,
  updateUserTwoFactor,
} from '../server-api/api';
import { PortalUser, USER_ROLE } from '../server-api/model';
import { profileUpdatedEvent, toastSubject, userUpdatedSubject } from './rxjs';

export const userRoles = {
  Manager: USER_ROLE.MANAGER,
  'View only': USER_ROLE.VIEW_ONLY,
  Update: USER_ROLE.UPDATE,
};

export const userRolesWithoutUpdate = {
  'Manager (no update)': USER_ROLE.MANAGER_NO_UPDATE,
  'View only': USER_ROLE.VIEW_ONLY,
};

export const userRolesReversed = {
  MANAGER: 'Manager',
  MANAGER_NO_UPDATE: 'Manager (no update)',
  VIEW_ONLY: 'View only',
  UPDATE: 'Update',
};

export const userStatus = {
  Active: 'active',
  Inactive: 'inactive',
};

export enum USER_ACTION {
  ROLE = 'ROLE',
  STATUS = 'STATUS',
  TWO_FACTOR = 'TWO_FACTOR',
}

export const showInactiveSubject = new Subject<boolean>();
const showInactiveEvent = showInactiveSubject.asObservable();

const newUserTemplate = {
  active: true,
  admin: false,
  email: '',
  firstName: '',
  lastName: '',
  login: '',
  id: '',
  position: '',
  role: userRoles['View only'],
  batchEmail: false,
  fileUploadedEmail: false,
  contractGroups: [],
};

export class UsersState {
  public uploadNotifications: boolean = false;
  public filesNotifications: boolean = false;
  public emailNotifications = new Map();
  public showInactiveUsers: boolean = false;

  public selectedUsers: Map<PortalUser, true> = new Map();
  public users: PortalUser[] = [];
  public selectedAllUsers: boolean = false;

  public userToEdit: PortalUser | null = null;
  public userToEditLogin = '';

  public submittingUser = false;

  public submittingAction = false;
  public creatingUser = false;
  public resettingUserPassword = false;

  public gettingUsers = false;

  public usersSet = false;

  public initialized = false;

  public targetRole: string = '';
  public targetStatus: boolean = false;
  public targetTwoFactor: boolean = false;

  public userToResetPassword: PortalUser | null = null;
  constructor() {
    showInactiveEvent
      .pipe(debounceTime(SHORT_DEBOUNCE_DURATION))
      .subscribe((inactive) => {
        this.getUsers();
      });
    profileUpdatedEvent.subscribe(() => {
      this.getUsers();
    });
  }

  public checkActions = (action: USER_ACTION) => {
    if (this.selectedUsers.size === 0) {
      return { error: 'No users selected' };
    }
    switch (action) {
      case USER_ACTION.ROLE:
        let allActive = true;
        Array.from(this.selectedUsers.keys()).forEach((user) => {
          if (!user.active) {
            allActive = false;
          }
        });
        return {
          error: allActive
            ? undefined
            : 'You have selected one or more inactive users. Roles can only be changed for active users.',
          success: allActive,
        };
      case USER_ACTION.TWO_FACTOR:
        return { success: true };
      default:
        return { success: true };
    }
  };

  public init() {
    this.initialized = true;
    this.getUsers();
  }

  public getUsers = () => {
    this.selectedUsers.clear();
    this.selectedAllUsers = false;
    this.gettingUsers = true;
    getUsers(this.showInactiveUsers)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.gettingUsers = false;
        res.data.forEach((user) => {
          user.fullName =
            user.firstName && user.lastName
              ? user.lastName + ', ' + user.firstName
              : user.firstName
              ? user.firstName
              : user.lastName
              ? user.lastName
              : '—';
        });
        this.users = res.data;
      })
      .catch((err) => {
        toastSubject.next(err.message);

        this.gettingUsers = false;
      });
  };

  public prepareNewUser = () => {
    this.creatingUser = true;
    this.userToEditLogin = '';
    return new Promise<void>((resolve) => {
      this.userToEdit = { ...newUserTemplate };
      resolve();
    });
  };

  public submitUser = (user: PortalUser) => {
    return new Promise<void>((resolve, reject) => {
      this.submittingUser = true;
      const userId = user.id;
      delete (user as any).id;
      delete user.fullName;
      if (this.creatingUser) {
        createUser(user)
          .then((res) => {
            if (res.error) {
              throw new Error(res.error);
            }
            this.submittingUser = false;
            toastSubject.next(PopupEvent.USER_NEW_CREATED);
            this.getUsers();
            resolve();
          })
          .catch((err) => {
            this.submittingUser = false;
            toastSubject.next(err.message);

            reject();
          });
      } else {
        updateUser(userId, user)
          .then((res) => {
            if (res.error) {
              throw new Error(res.error);
            }
            this.submittingUser = false;
            toastSubject.next(PopupEvent.USER_UPDATED);
            userUpdatedSubject.next();
            this.getUsers();
            resolve();
          })
          .catch((err) => {
            this.submittingUser = false;
            toastSubject.next(err.message);

            reject();
          });
      }
    });
  };

  public updateStatus = (status: boolean) => {
    const userIds: string[] = [];
    this.submittingAction = true;
    Array.from(this.selectedUsers).forEach(([user, value]) => {
      userIds.push(user.id);
    });

    return updateUserStatus(userIds, status)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        toastSubject.next(
          'The users have been set to ' + (status ? 'active' : 'inactive') + '.'
        );
        this.submittingAction = false;
        this.getUsers();
      })
      .catch((err) => {
        this.submittingAction = false;
        toastSubject.next(err.message);
      });
  };

  public updateTwoFactor = (twoFactor: boolean) => {
    const userIds: string[] = [];
    this.submittingAction = true;
    Array.from(this.selectedUsers).forEach(([user, value]) => {
      userIds.push(user.id);
    });

    return updateUserTwoFactor(userIds, twoFactor)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        toastSubject.next(
          "The users' two-factor setting has been " +
            (twoFactor ? 'enabled' : 'disabled') +
            '.'
        );
        this.submittingAction = false;
        this.getUsers();
      })
      .catch((err) => {
        this.submittingAction = false;
        toastSubject.next(err.message);
      });
  };

  public updateRole = (role: USER_ROLE) => {
    const userIds: string[] = [];
    this.submittingAction = true;
    Array.from(this.selectedUsers).forEach(([user, value]) => {
      userIds.push(user.id);
    });

    return updateUserRole(userIds, role)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        toastSubject.next(PopupEvent.USER_ROLE_UPDATE);
        this.submittingAction = false;
        this.getUsers();
      })
      .catch((err) => {
        toastSubject.next(err.message);
        this.submittingAction = false;
      });
  };

  public chooseUserToEdit = (user: PortalUser) => {
    this.creatingUser = false;
    this.userToEditLogin = user.login;
    this.userToEdit = observable({ ...newUserTemplate, ...user });
  };

  public resetPassword = () => {
    return new Promise<void>((resolve, reject) => {
      if (!this.userToResetPassword) {
        return;
      }
      const { login, email } = this.userToResetPassword;
      this.submittingAction = true;
      resetUserPassword(login)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.submittingAction = false;
          const emailNode = React.createElement(
            'b',
            { style: { width: '100%', display: 'inline-block' } },
            email + '.'
          );
          const toastContent = React.createElement(
            React.Fragment,
            {},
            'A link allowing the user to create a new password has been sent to ',
            emailNode
          );
          toastSubject.next(toastContent);
          resolve();
        })
        .catch((err) => {
          this.submittingAction = false;
          toastSubject.next(err.message);

          reject();
        });
    });
  };

  public toggleShowInactiveUsers = (e: React.FormEvent<HTMLInputElement>) => {
    showInactiveSubject.next(e.currentTarget.checked);
    this.showInactiveUsers = e.currentTarget.checked;
  };

  public toggleSelectAllUsers = (e: React.FormEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    this.selectedAllUsers = isChecked;
    this.selectedUsers.clear();
    if (isChecked) {
      this.users.forEach((user) => {
        this.selectedUsers.set(user, isChecked);
      });
    }
  };
}

decorate(UsersState, {
  showInactiveUsers: observable,
  users: observable,
  selectedUsers: observable,
  selectedAllUsers: observable,
  initialized: observable,
  userToResetPassword: observable,
  submittingUser: observable,
  submittingAction: observable,
  userToEdit: observable,
  userToEditLogin: observable,
  creatingUser: observable,
  resettingUserPassword: observable,
  gettingUsers: observable,
  targetStatus: observable,
  targetRole: observable,
});

export const usersContext = createContext(new UsersState());
