import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../../../config';
import { DebtorArrangement } from '../../../../../server-api/model';
import { GenericSorter } from '../../../../../state/genericSorter';
import { formatNumber } from '../../../../../utils/formatNumber';
import { SortHeaderCell } from '../../../../common/table/SortHeaderCell';
import { ModalProps } from '../../../../modals/Modals';
import './PaymentSchedule.css';

export const PaymentScheduleModal = observer(
  ({
    showModal,
    closeModal,
    arrangement,
  }: ModalProps & { arrangement: DebtorArrangement }) => {
    const debtorArrangement = arrangement;
    const unsortedEntries = useMemo(
      () => (debtorArrangement && debtorArrangement.paymentPlan) || [],
      [debtorArrangement]
    );
    const [sorter, setSorter] = useState(
      new GenericSorter(unsortedEntries, 'paymentScheduleDate', true)
    );

    useEffect(() => {
      const newSorter = new GenericSorter(
        unsortedEntries,
        sorter.sortBy,
        sorter.sortAscending
      );
      setSorter(newSorter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unsortedEntries]);

    if (!debtorArrangement) {
      return null;
    }

    if (!debtorArrangement.paymentPlan) {
      return null;
    }

    const hasInterestIncurred = sorter.sortedEntries.some(payment => payment.interestIncurred);
    return (
      <>
        <div>
          <div role='table' aria-label='Payment Schedule' className='table'>
            <div role='rowgroup'>
              <div role='row' className='thead'>
                <SortHeaderCell
                  type='paymentScheduleDate'
                  sorter={sorter}
                  text='Date'
                  className='payment-plan-cell-date'
                />
                <SortHeaderCell
                  type='paymentScheduleAmount'
                  sorter={sorter}
                  text='Amount'
                  className='payment-plan-cell-amount'
                />
                {hasInterestIncurred && (
                    <SortHeaderCell
                        type='paymentScheduleInterestIncurred'
                        sorter={sorter}
                        text='Interest Incurred'
                        className='payment-plan-cell-interest-incurred'
                      />
                  )}
              </div>
            </div>
            <div role='rowgroup' className='tbody'>
              {sorter.sortedEntries.map((payment, index) => {
                return (
                  <div
                    role='row'
                    key={payment.amount + payment.date + index}
                    className='tr payment-plan-entry'
                  >
                    <div role='cell' className='td'>
                      {moment(payment.date).format(DATE_FORMAT_SHORTER)}
                    </div>
                    <div role='cell' className='td'>£{formatNumber(payment.amount)}</div>
                    {hasInterestIncurred && (
                        <div role='cell' className='td'>£{formatNumber(payment.interestIncurred)}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='modal-actions'>
          <Button onClick={closeModal} className='btn btn-gray'>
            Close
          </Button>
        </div>
      </>
    );
  }
);
