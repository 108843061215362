import { DIVISION } from '../config';

export interface ServerResponse<T> {
  data: T;
  error?: any;
}

export interface LoginResponse {
  twoFactor?: boolean;
  sessionId: string;
  attemptsLeft?: number;
  changePasswordAtNextLogon: boolean;
  passNotChangedYet: boolean;
  logoId: string;
  portalRole: USER_ROLE;
  theme: DIVISION;
  localAdministrator: boolean;
}

export interface ProfileData {
  twoFactorAuthenticationEnabled?: boolean;
  twoFactorAuthenticationConfigured?: boolean;
  oneStepId: number;
  login: string;
  firstName: string;
  lastName: string;
  position: any;
  email: string;
  lastLogin: number;
  active: any;
  admin: any;
  role: any;
  batchEmail?: boolean;
  contractGroups: { id: string; name: string }[];
  fileUploadedEmail?: boolean;
  sessionTimeoutSec?: number;
}

export interface PortalUser {
  position: string;
  firstName: string;
  lastName: string;
  login: string;
  email: string;
  lastLogin?: number;
  id: string;
  active: boolean;
  admin?: boolean;
  twoFactorAuthenticationEnabled?: boolean;
  twoFactorAuthenticationConfigured?: boolean;
  role: USER_ROLE;
  batchEmail?: boolean;
  fileUploadedEmail?: boolean;
  fullName?: string;
  contractGroups: { id: string; name: string }[];
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  jobTitle: string;
  permissions: string[];
  administrator: boolean;
  lastLogin: Date;
  status: string;
  notifications: {
    batchUpload: boolean;
    filesAvailable: boolean;
  };
}

export interface SearchEntryResponse {
  cases: Case[];
  debtBalance: number;
  debtor: DebtorDto;
  warningFlag: boolean;
  vulnerableFlag: boolean;
}

export interface SearchCaseEntryResponse {
  cases: Case;
  warningFlag: boolean;
  vulnerableFlag: boolean;
}

export interface DebtorDetailsResponse {
  additionalParties: string[];
  warningFlag: boolean;
  vulnerableFlag: boolean;
  debtBalance: number;
  name: string;
  otherLiable?: string;
  previousName?: string;
  cases: Case[];
  debtorDto: DebtorDto;
  benefitsReceipt: boolean;
}

export interface Categories {
  [humanName: string]: string;
}

export interface ExchangeFileCategory {
  id: string;
  name: string;
}

export interface DebtorArrangement {
  arrangement?: {
    id: string;
    status: string;
    osArrInitialAmount: number;
    osArrAmount: number;
    osArrInitialDate: number;
    lastPaymentDate: number;
    osArrNextPaymentDate: number;
    interval: string;
  };
  paymentPlan?: [
    {
      date: number;
      amount: number;
      interestIncurred : number;
    }
  ];
  nextPaymentAmount?: number;
  remaining?: number;
  lastPaymentDate?: number;
}

export enum COURT_APPOINTMENT_STATUS {
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  SUCCESSFUL = 'SUCCESSFUL',
  BROKEN = 'BROKEN',
}

export interface CourtAppointment {
  appointmentId: string;
  caseDtos?: Case[];
  startTime: number;
  slotDate: number;
  status: string;
  appointmentPurpose: string;
  courtName: string;
}

export interface CourtAppointmentRequest {
  appointmentId: string;
  appointmentStatus: string;
  comment: string;
}


export interface Statement {
  amountTotal: number;
  balanceTotal: number;
  paidTotal: number;
  vatTotal: number; // included in totals

  debtAmountSubTotal: number;
  debtBalanceSubTotal: number;
  debtPaymentsSubTotal: number;
  debtVatSubTotal: number; // included in totals
  debtWriteOffSubTotal: number;
  debtBalanceWithSettlementSubTotal: number;

  interestAmountSubTotal: number;
  interestBalanceSubTotal: number;
  interestPaymentsSubTotal: number;
  interestVatSubTotal: number; // included in totals
  interestWriteOffSubTotal: number;
  interestBalanceWithSettlementSubTotal: number;

  feesAmountSubTotal: number;
  feesBalanceSubTotal: number;
  feesPaymentsSubTotal: number;
  feesVatSubTotal: number; // included in totals
  feesWriteOffSubTotal: number;
  feesBalanceWithSettlementSubTotal: number;

  totalWaitingPayments: number;

  debtFees: DebtFee[];
  interestFees: DebtFee[];
  fees: DebtFee[];
}

export interface DebtFee {
  amount: number;
  vatAmount: number;
  remittedAmount: number;
  remittedVat: number;
  balance: number;

  arthurFeeType?: { name: string };
  date: number;
  oneStepId: null;

  vatWhoPays: string;

  // unused
  feeWhoPays: string;
  paidAmount: number;
  paidVat: number;
  paidAmountWithRemitted: number;
  paidVatWithRemitted: number;
  paidAmountWithVatWithRemitted: number;
}

export interface Payment {
  payment?: {
    amount: number;
    date: number;
    oneStepId: number;
    paymentReference: string;
    paymentType: string;
    remittanceBatch: {
      batchNumber: number;
    };
    splitFees: number;
    splitVat: number;
    status: string;
  };
  toUs: number;
  toClient: number;
}

export interface DebtorDto {
  id: string;
  isCompany: boolean;
  contractor: DebtorContractor;
  mainAddress?: DebtorAddress;
  previousAddress?: DebtorAddress;
  arrangement?: {
    id: string;
    status: string;
    osArrInitialAmount: number;
    osArrAmount: number;
    osArrInitialDate: number; // Date
    lastPaymentDate: number; // Date
    osArrNextPaymentDate: number; // Date
    interval: string;
  };
  benefits?: string[];
  debtBalance?: number;
  debtorName?: string;
  alsoKnownAs?: string;
  score?: string;
  isVulnerable?: boolean;
  cases: Case[];
  filteredCases?: Case[];
  actualRiskAssessment?: {
    id: string;
    status: string;
    comment: string;
    riskRating: any;
    assessmentDate: number;
  };
  warning?: boolean;
  meansForm: {
    employmentName: string;
    employmentPhoneNumber: string;
    emailAddress: string;
    employmentAddress: string;
  };
  debtorScore?: {
    explanation?: string;
    score: number;
    scoreLevel: string;
  };
}

export interface Case {
  id: string;
  oneStepId: number; // caseRef
  clientReference: string;
  altClientReference1?: string;
  altClientReference2?: string;
  altClientReference3?: string;
  contract: {
    name: string;
    oneStepId: number;
    workType: {
      hasPayments: boolean;
      workTypeGroup?: {
        code?: 'HMCTS' | 'ROAD_TRAFFIC';
      };
    };
  };
  warrant?: {
    liabilityOrderDate: number;
    warrantDate: number;
    dueDate: number;
    offenceNumber?: string;
  };
  debtor: DebtorDto;
  lastStage?: {
    name: string;
  };
  status?: string; // "LIVE" "CANCELLED"
  isOnHold: boolean;
  closed: boolean;
  debtBalance: number;
  debtInitial: number;
  debtAmount: number;
  interestInitial?: number;
  interestAmount?: number;
  debtOnly: number;
  originalVrm?: string;
  details?: CaseDetails;
  debtorName?: string;
  postcode?: string;
  additionalPartyName?: string;
  additionalPartyType?: string;
}

export interface DebtorContractor {
  contactDetails: { value: string; type?: string }[];
  name: string;
  surname: string;
  title?: string;
  tradingName?: string;
  birthDate?: number;
  addresses?: DebtorAddress[];
  gender?: string;
}

export interface DebtorAddress {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  line5?: string;
  line6?: string;
  postcode: string;
  splitAddress: string;
}

export interface CaseDetails {
  id: string;
  hasPayments: boolean;
  caseId: number; // caseRef
  laRevenuesSection?: LiabilityOrder;
  debtRecoverySection?: DebtRecovery;
  roadTrafficSection?: WarrantDetails;
  laArrestSection?: ArrestSection;
  hmctsSection?: HMCTSSection;
  agentSection?: {
    name: string;
    type: string;
    allocated: number; // Date
    contact: string;
  };
  highCourtWritSection?: HighCourtWritSection;
  referredTo: number;
  contract: string;
  batchNumber: number;
  caseStatus: string;
  lastAction: number; // Date
  holdStatus: string;
  type: string;
  holdReason: string;
  holdExpires: number;
  returnCode: number;
  returnDescription: string;
  returnDate: number;
  remittanceBatchId: number;
}

export interface HMCTSSection {
  nameOnWarrant: string;
  additionalDays: number;
  warrantDate?: number;
  warrantNumber?: string;
  warrantType?: string;
  hearingDate?: number;
  parentToPay?: boolean;
  gender: 'MALE' | 'FEMALE' | 'UNKNOWN';
  offences: string[];
  additionalPartyTitle: string;
  additionalPartyAddress: string;
  youth: boolean;
}
export interface ArrestSection {
  nameOnWarrant: string;
  warrantDate: number;
  details: string;
}
export interface HighCourtWritSection {
  nameOnWrit: string;
  claimNo: string;
  particularsOfClaim: string;
  caseHandler: string;
  ccjDate: number;
  ccjCourt: string;
  writDate: number;
  writCourt: string;
  claimant: string;
}

export interface LiabilityOrder {
  nameOnLiabilityOrder: string;
  summonsNumber: string;
  offenceFrom: number;
  periodTo: number;
  liabilityOrderDate: number;
  details: string;
}
export interface DebtRecovery {
  debtorName: string; //"Rashif, DABATA Miss";
  summonsNumber: string;
  debtFrom: number;
  due: number;
  debt: string;
  details: string;
}
export interface WarrantDetails {
  nameOnWarrant: string; // "Nandeep, GEZI";
  vrm: string; //"EX62DGU";
  offence: string; // "1, Parked in a restricted street during prescribed hours";
  details: string; // "some details";
  location: string; // "Crescent Road";
  offenceDate: number;
  warrantDate: number;
  vehicleDetails: string; // "EX62DGU Nissan 1n/a, Blue, 2012";
}

export interface FilterInterface {
  caseDetails: {
    clientRef?: string;
    summonsNo?: string;
    vrm?: string;
    batch?: string;
    jbwRef?: string;
    claimantName?: string;
  };
  caseStatus: {
    closed?: string;
    status?: string;
    stage?: string;
    onHold?: string;
    activeAgent?: boolean;
    cga?: boolean;
  };
  customerDetails: {
    lastName?: string;
    firstName?: string;
    postcode?: string;
    isCompany?: boolean;
    vulnerable?: boolean;
    warning?: boolean;
    brokenArrangement?: boolean;
    liveCasesCountFrom?: number;
    liveCasesCountTo?: number;
  };
  dates: {
    warrantFrom?: number;
    warrantTo?: number;
    offenceFrom?: number;
    offenceTo?: number;
    dueFrom?: number;
    dueTo?: number;
    referredFrom?: number;
    referredTo?: number;
  };
  contracts: string[];
  pasteText?: string;
  pasteType?: string;
  bookmarks: { cases: string[]; debtors: string[] };
}

export interface BookmarkedThing {
  id: string;
  caseId?: string;
  debtorId: string;
  prefix?: string;
  name: string;
  caseClosed?: boolean;
  createTs: number;
  type: BOOKMARK_TYPE | BOOKMARK_TYPE_STRING;
}

export enum BOOKMARK_TYPE {
  CASE = 200,
  DEBTOR = 100,
}
export enum BOOKMARK_TYPE_STRING {
  CASE = 'CASE',
  DEBTOR = 'DEBTOR',
}

export interface RecentDebtor {
  id: string;
  name: string;
  prefix?: string;
  date: number;
}

export interface SavedSearch {
  id: string;
  searchString: string;
  name: string;
  createTs: number;
}

export interface DashboardSummary {
  status: string;
  count: number;
  sum: number;
}

export interface SearchQuery {
  query: 'TODO';
}

export interface HistoryEntry {
  date: Date;
  user: string;
  type: string;
  description: string;
  case: string;
}

export interface Category {
  checked: boolean;
  amount: number;
}

export interface Letter {
  id: string;
  letterType?: { name: string };
  sentDate: number;
  channelUsed: string;
}

export interface LetterCase {
  id: string;
  clientReference: string;
  oneStepId: string;
  warrant?: {
    warrantDate?: number;
    liabilityOrderDate?: number;
    offenceDate?: number;
  };
}

export interface InfoFile {
  debtorFileType: string;
  fileDescriptor: {
    id: string;
    name: string;
  };
  uploadDate: number;
}

export interface DownloadsFile {
  date: Date;
  type?: string;
  filename: string;
  downloadedDate: Date;
  username: string;
}

export interface HistoryData {
  categories: HistoryCategory[];
  caseCounts: HistoryCase[];
  notes: HistoryNote[];
  customerLevelCount: number;
}

export interface HistoryCategory {
  name: string;
  count: number;
}

export interface HistoryNote {
  date: number;
  osCreatedBy: string;
  text: string;
  cases: Case[];
  noteType: {
    clientPortalCategory: {
      name: string;
    };
    caption: string;
  };
}

export interface HistoryCase {
  id: string;
  count: number;
  clientReference: string;
  oneStepId: number;
  warrantDate?: number;
  liabilityOrderDate?: number;
  dueDate?: number;
}

export interface ExchangeFile {
  fileDescriptor: {
    id: string;
    name: string;
  };
  uploadDate: number;
  downloadDate?: number; // optional
  downloadedBy?: string; // optional
  clientPortalFileType: { id: string; name: string };
}

export interface ReportParam {
  name: string;
  alias: string;
  type: string;
  required: boolean;
}

export enum USER_ROLE {
  VIEW_ONLY = 'VIEW_ONLY',
  UPDATE = 'UPDATE',
  MANAGER = 'MANAGER',
  MANAGER_NO_UPDATE = 'MANAGER_NO_UPDATE',
}

export interface OtherContact {
  type: string | number;
  value?: string;
}

export interface BookmarksResponse {
  userId: string;
  bookmarks: BookmarkedThing[];
  searches: SavedSearch[];
}

export interface Settlement {
  createTs: number;
  status: string;
  targetDate: number;
  writeOffAmount: number;
}

export interface PortalConfig {
  hideFiltersDatesWarrant: boolean;
  hideFiltersDatesOffence: boolean;
  hideFiltersDatesDue: boolean;
  hideFiltersDatesReferred: boolean;
  hideFiltersClaimant: boolean;
  hideCustomerScore: boolean;
  hideCaseEnforcementAgent: boolean;
  assignOperatorRoleAllowed: boolean;
}
