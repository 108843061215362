import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import React, { FC, useContext, useRef } from 'react';
import { DebtorDto } from '../../../server-api/model';
import { actionsContext } from '../../../state/actionsState';
import { searchContext } from '../../../state/search/searchState';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { IconSortDown, IconSortUp } from '../../common/icons';
import { InputSelect } from '../../common/input/InputSelect';
import { Spinner } from '../../common/spinner/Spinner';
import { DebtorCard } from './DebtorCard';
import './DebtorView.css';
import { FlatList } from './FlatList';
import { appState } from 'state/appState';

export const DebtorView = observer(() => {
  const searchState = useContext(searchContext);
  const actionsState = useContext(actionsContext);

  const selectAllDebtors = (e: React.FormEvent<HTMLInputElement>) => {
    actionsState.toggleSelectAllDebtors(e.currentTarget.checked);
  };

  const selectAllCases = (e: React.FormEvent<HTMLInputElement>) => {
    actionsState.toggleSelectAllCases(e.currentTarget.checked);
  };

  if (!searchState.searchedDebtorsState.displayedDebtors.length) {
    return <h1 style={{ padding: '20px 40px' }}>Nothing to display.</h1>;
  }

  const sortOptions = {
    'Sort by name': 'surname',
    'Sort by postcode': 'postcode',
    'Sort by score': 'scoreLevel',
    'Sort by arrangement': 'arrangementStatus',
    'Sort by warning': 'warningFlag',
    'Sort by vulnerable': 'isVulnerable',
    'Sort by balance': 'debtBalanceDebtor'
  }

  if (appState.portalConfig.hideCustomerScore) {
    delete (sortOptions as any)['Sort by score'];
  }

  return (
    <div className='DebtorView'>
      <div className='row-above-table'>
        <Checkbox
          checked={actionsState.selectedAllDebtors}
          onChange={selectAllDebtors}
          inverse={true}
          label='Select all displayed customers'
        />
        <div className='sort-wrapper'>
          {searchState.searchedDebtorsState.debtorSorter.sortAscending ? (
            <IconSortUp />
          ) : (
            <IconSortDown />
          )}
          <InputSelect
            className='debtor-sort'
            name='debtorSort'
            options={sortOptions}
            selectedThing={searchState.searchedDebtorsState.debtorSorter.sortBy}
            onChange={(val) => {
              searchState.searchedDebtorsState.debtorSorter.setSortBy(val);
            }}
          />
        </div>
        <Checkbox
          checked={actionsState.selectedAllCases}
          onChange={selectAllCases}
          label='Select all displayed cases'
        />
      </div>

      <DebtorTable
        debtorsToRender={searchState.searchedDebtorsState.displayedDebtors}
      />

      {searchState.loadingMore ? (
        <div style={{ margin: '20px' }}>
          <Spinner centered={true} block={true} />
        </div>
      ) : searchState.searchedDebtorsState.originalDebtors.length >
        searchState.searchedDebtorsState.displayedDebtors.length ? (
        <Button
          className='btn btn-primary btn-loadmore'
          onClick={searchState.searchedDebtorsState.incrementRenderStep}
        >
          Show more
        </Button>
      ) : searchState.searchedDebtorsState.count >
        searchState.searchedDebtorsState.originalDebtors.length ? (
        <Button
          className='btn btn-primary btn-loadmore'
          onClick={() => {
            searchState.runNormalSearchDebtorsMore();
          }}
        >
          Show more
        </Button>
      ) : null}
    </div>
  );
});

interface ListProps {
  debtorsToRender: DebtorDto[];
}

const DebtorTable: FC<ListProps> = observer(({ debtorsToRender }) => {
  const ref = useRef<HTMLDivElement>(null);
  const actionsState = useContext(actionsContext);

  return (
    <div className='debtors-table' ref={ref}>
      <FlatList estimatedRowSize={300}>
        {debtorsToRender.map((debtor) => {
          return <DebtorCard key={'debtor-' + debtor.id} debtor={debtor} />;
        })}
      </FlatList>
    </div>
  );
});
