import React, { FC } from 'react';
import moment from 'moment';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import { HighCourtWritSection } from '../../../../server-api/model';

interface SectionProps {
  details: HighCourtWritSection;
}
export const SectionHighCourtWrit: FC<SectionProps> = ({ details }) => {
   return (
      <div className='high-court-writ-details'>
        <h2>Writ details</h2>
        <div className='flex-group'>
          <div>
            <table>
              <tbody>
                <tr>
                  <th>Name on writ</th>
                  <td className='multiline-container'>
                    {details.nameOnWrit}
                  </td>
                </tr>

                <tr>
                  <th>Claim no.</th>
                  <td className='multiline-container'>
                    {details.claimNo}
                  </td>
                </tr>

                <tr>
                  <th>Particulars of claim</th>
                  <td className='multiline-container'>
                    {details.particularsOfClaim}
                  </td>
                </tr>

                <tr>
                  <th>Case handler</th>
                  <td className='multiline-container'>
                    {details.caseHandler}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table>
              <tbody>
                <tr>
                  <th>CCJ date</th>
                  <td className='multiline-container'>
                    {details.ccjDate ? moment(details.ccjDate).format(DATE_FORMAT_SHORTER) : ''}
                  </td>
                </tr>

                <tr>
                  <th>CCJ court</th>
                  <td className='multiline-container'>
                    {details.ccjCourt}
                  </td>
                </tr>

                <tr>
                  <th>Writ date</th>
                  <td className='multiline-container'>
                    {details.writDate ? moment(details.writDate).format(DATE_FORMAT_SHORTER) : ''}
                  </td>
                </tr>

                <tr>
                  <th>Writ court</th>
                  <td className='multiline-container'>
                    {details.writCourt}
                  </td>
                </tr>

                <tr>
                  <th>Claimant</th>
                  <td className='multiline-container'>
                    {details.claimant}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
