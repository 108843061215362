import React, {FC, useRef} from 'react';
import {SortHeaderCell} from '../../common/table/SortHeaderCell';
import moment from 'moment';
import {DATE_FORMAT_SHORTER, DATE_TIME_FORMAT_SHORTER} from '../../../config';
import {formatNumber} from '../../../utils/formatNumber';

import {GenericSorter} from '../../../state/genericSorter';
import {useHorizontalDragScroll} from '../../../utils/hooks';

import './SettlementsTable.css';
import {observer} from 'mobx-react';
import {Settlement} from '../../../server-api/model';

export const SettlementsTable: FC<{
    sorter: GenericSorter<Settlement>;
    labelledBy?: string;
}> = observer(({sorter, labelledBy}) => {
    const ref = useRef<HTMLDivElement>(null);

    useHorizontalDragScroll(ref);

    return (
        <div className='settlements-container' ref={ref}>
            <table className='settlements__table table-v2' aria-labelledby={labelledBy}>
                <thead>
                <tr>
                    <SortHeaderCell
                        mode='td'
                        sorter={sorter}
                        type='settlementCreated'
                        text='Created'
                        className='settlement__created'
                    />
                    <SortHeaderCell
                        mode='td'
                        sorter={sorter}
                        type='settlementStatus'
                        text='Status'
                        className='settlement__status'
                    />
                    <SortHeaderCell
                        mode='td'
                        sorter={sorter}
                        type='settlementTargetDate'
                        text='Target date'
                        className='settlement__targetdate'
                    />
                    <SortHeaderCell
                        mode='td'
                        sorter={sorter}
                        type='settlementWriteOff'
                        text='Write off'
                        className='settlement__writeoff'
                    />
                </tr>
                </thead>
                <tbody>
                {sorter.sortedEntries.map((settlement, index) => {
                    if (!settlement) {
                        return null;
                    }
                    return (
                        <tr key={'settlement' + index}>
                            <td className='settlement__created'>
                                {moment(settlement.createTs).format(DATE_TIME_FORMAT_SHORTER)}
                            </td>
                            <td className='settlement__status'>
                                {settlement.status}
                            </td>
                            <td className='settlement__targetdate'>
                                {moment(settlement.targetDate).format(DATE_FORMAT_SHORTER)}
                            </td>
                            <td className='settlement__writeoff'>
                                £{formatNumber(settlement.writeOffAmount)}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
});
