import { Button } from 'components/common/button/Button';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CASE_VIEW_STEP } from '../../../config';
import { USER_ROLE } from '../../../server-api/model';
import { actionsContext } from '../../../state/actionsState';
import { appContext } from '../../../state/appState';
import { toastSubject } from '../../../state/rxjs';
import { CaseSorter } from '../../../state/caseSorter';
import { caseContext } from '../../../state/caseState';
import { debtorContext } from '../../../state/debtorState';
import { modalContext } from '../../../state/modalState';
import { profileContext } from '../../../state/profileState';
import { Actions } from '../../common/actions';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { Spinner } from '../../common/spinner/Spinner';
import './InfoCases.css';
import { InfoCaseTable } from './InfoCaseTable';
import { useTabTitle } from '../../../utils/hooks';

export const InfoCases = observer(() => {
  useTabTitle(`Cases | ${window.COMPANY} Client Portal`);
  const caseState = useContext(caseContext);
  const debtorState = useContext(debtorContext);
  const AS = useContext(actionsContext);
  const appState = useContext(appContext);
  const profileState = useContext(profileContext);

  const debtor = debtorState.loadedDebtor;
  const { showClosed } = appState;

  const actions = new Actions(useContext(modalContext)).getActions();

  const unsortedCases = showClosed ? caseState.cases : caseState.filteredCases;

  const [caseSorter, setCaseSorter] = useState(
    new CaseSorter(unsortedCases, 'warrantDate', false)
  );
  const [cases, setCases] = useState(
    caseSorter.sortedEntries.slice(0, CASE_VIEW_STEP)
  );
  const [currentStep, setCurrentStep] = useState(CASE_VIEW_STEP);
  const currentStepRef = useRef(currentStep);

  useEffect(() => {
    const disposer = reaction(
      () => {
        return [caseSorter.sortBy, caseSorter.sortAscending];
      },
      (data, reaction) => {
        setCases(caseSorter.sortedEntries.slice(0, currentStepRef.current));
      }
    );
    return () => {
      AS.clearSelections();
      disposer();
    };
  }, [caseSorter, cases, AS]);

  useEffect(() => {
    const newCaseSorter = new CaseSorter(
      unsortedCases,
      caseSorter.sortBy,
      caseSorter.sortAscending
    );
    setCaseSorter(newCaseSorter);
    setCases(newCaseSorter.sortedEntries.slice(0, currentStepRef.current));
    AS.clearSelections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showClosed, unsortedCases, AS]);

  const showMore = () => {
    const nextStep = currentStep + CASE_VIEW_STEP;
    setCurrentStep(nextStep);
    setCases(caseSorter.sortedEntries.slice(0, nextStep));
  };

  if (!debtor) {
    return (
      <div className='InfoCases placeholder'>
        <div className={'action-buttons '}>
          {actions.map((action, index) => {
            return (
              <Button key={'button-' + index} disabled={true}>
                {action.name}
              </Button>
            );
          })}
        </div>
        <div className='row-above-table'>
          <Checkbox
            onChange={() => {}}
            checked={false}
            label='Select all displayed cases'
          />
        </div>
        <div style={{ padding: '22px' }}>
          <Spinner centered={true} block={true} />
        </div>
      </div>
    );
  }

  return (
    <div className='InfoCases'>
      <div className={'action-buttons '}>
        {actions.map((action, index) => {
          const actionCheck = AS.checkActions(action.scope);
          return (
            <Button
              key={'button-' + index}
              disabled={!action.roles.includes(profileState.portalRole as USER_ROLE) || !actionCheck.success}
              mode='success'
              onClick={() => {
                if (actionCheck.success) {
                  action.action();
                } else if (actionCheck.error) {
                  toastSubject.next(actionCheck.error);
                }
              }}
            >
              {action.name}
            </Button>
          );
        })}
      </div>
      <div className='row-above-table'>
        <Checkbox
          checked={AS.selectedAllCasesOnDebtors.get(debtor.id)}
          onChange={(e) =>
            AS.toggleSelectCasesOnDebtor(
              e.currentTarget.checked,
              showClosed ? caseState.cases : caseState.filteredCases,
              debtor
            )
          }
          label='Select all displayed cases'
        />
      </div>

      <div className='cases-table'>
        {cases.length ? (
          <InfoCaseTable entries={cases} sorter={caseSorter} />
        ) : (
          <p>
            This customer doesn't have any {appState.showClosed ? '' : 'open'}{' '}
            cases
          </p>
        )}

        {cases.length < unsortedCases.length && cases.length !== 0 ? (
          <Button className='btn btn-primary btn-loadmore' onClick={showMore}>
            Show more
          </Button>
        ) : null}
      </div>
    </div>
  );
});
