import { action, observable, transaction } from 'mobx';
import { createContext } from 'react';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PopupEvent } from '../components/common/popup/Popup';
import {
  DIVISION,
  SESSION_REFRESH_INTERVAL,
  INACTIVITY_LOGOUT_TIME_MILLISECONDS,
} from '../config';
import { registerInactivityDetection } from '../registerInactivityDetection';
import {
  getDivision,
  getUserInfo,
  login,
  logout,
  resetPassword,
  setupTwoFactor,
  updateUserInfo,
} from '../server-api/api';
import {
  CommunicationEvent,
  communicationEvents,
} from '../server-api/communications';
import { ProfileData, USER_ROLE } from '../server-api/model';
import {
  loginSubject,
  logoutSubject,
  profileUpdatedSubject,
  themeSubject,
  toastSubject,
  userUpdatedEvent,
} from './rxjs';
import { runInAction } from 'mobx';
import { AppState, appState } from '../state/appState';

export class ProfileState {
  @observable
  username = '';
  @observable
  password = '';
  @observable
  updatingProfile = false;
  @observable
  profileData: null | ProfileData = null;
  @observable
  sessionId: string | null = null;
  @observable
  passwordExpired = false;
  @observable
  passNotChangedYet = false;
  @observable
  passwordExpiredLogin: null | string = null;
  @observable
  authenticated = false;
  @observable
  initialized = false;
  @observable
  loggingin = false;
  @observable
  requestingPasswordReset = false;
  @observable
  logoId: string | null = null;

  @observable
  otpauth: string = '';
  @observable
  otpUserData?: { key: string; verificationCode: string };

  @observable
  portalRole: USER_ROLE | null = null;
  @observable
  isAdmin = false;

  sessionPreserveInterval: NodeJS.Timeout | null = null;
  sessionExpirySub?: Subscription;

  private appState: AppState;

  constructor(appState: AppState) {
    this.init();
    this.appState = appState;
    communicationEvents
      .pipe(filter((e) => e === CommunicationEvent.FORBIDDEN))
      .subscribe(
        action(() => {
          if (!this.authenticated) {
            return;
          }
          this.logoutInactive();
        })
      );
    userUpdatedEvent.subscribe(() => {
      this.reloadUserInfo();
    });
  }

  init = () => {
    this.initialized = false;
    transaction(() => {
      const userData = JSON.parse(
        window.localStorage.getItem('userData') || '{}'
      );
      const sessionId = window.localStorage.getItem('sessionId');
      const { theme, logoId, portalRole, isAdmin, passwordExpired, passNotChangedYet } = userData;
      if (sessionId) {
        this.sessionId = sessionId;
        getUserInfo()
          .then((res) => {
            if (res.error) {
              throw new Error(res.error);
            }
            this.appState.initPortalConfig();
            if (theme) {
              //themeSubject.next(DIVISION.RUNDLES);
              Object.values(DIVISION).forEach((val) => {
                if (theme === val) {
                  themeSubject.next(theme);
                }
              });
            }
            if (logoId) {
              this.logoId = logoId;
            }
            if (passwordExpired) {
              this.passwordExpired = passwordExpired;
              this.passNotChangedYet = passNotChangedYet;
            }
            if (portalRole) {
              this.portalRole = portalRole as USER_ROLE;
            } else {
              this.portalRole = USER_ROLE.VIEW_ONLY;
            }
            if (isAdmin) {
              this.isAdmin = isAdmin;
            } else {
              this.isAdmin = false;
            }
            this.profileData = res.data;
            loginSubject.next(res.data.login);
            this.authenticated = true;
            this.initialized = true;
            this.initSessionPreservation(res.data.sessionTimeoutSec);
          })
          .catch((err) => {
            this.getInitialDivision();
          });
      } else {
        this.getInitialDivision();
      }
    });
  };

  getInitialDivision = () => {
    // themeSubject.next(DIVISION.RUNDLES);
    // this.initialized = true;
    // return;
    getDivision()
      .then((res) => {
        if (!res.data) {
          throw new Error('Failed to get active division');
        }
        themeSubject.next(res.data);
        this.initialized = true;
      })
      .catch((err) => {
        this.initialized = true;
        toastSubject.next(err.message);
        themeSubject.next(DIVISION.HITO);
      });
  };

  logout = () => {
    logout().catch((err) => {
      toastSubject.next(err.message);
    });
    this.doLogout();
  };

  logoutInactive = () => {
    this.doLogout();
    toastSubject.next(PopupEvent.LOGOUT_INACTIVE);
  };

  login = (code?: string): Promise<any> => {
    this.loggingin = true;
    const loginPromise = new Promise((resolve, reject) => {
      login(this.username, this.password, code)
        .then((res) => {
          if (res.data.sessionId) {
            const {
              sessionId,
              logoId,
              theme,
              portalRole,
              localAdministrator: isAdmin,
            } = res.data;
            const passwordExpired = res.data.changePasswordAtNextLogon;
            const passNotChangedYet = res.data.passNotChangedYet;

            window.localStorage.setItem(
              'userData',
              JSON.stringify({
                theme,
                logoId,
                portalRole,
                passwordExpired,
                passNotChangedYet,
                isAdmin,
              })
            );
            window.localStorage.setItem('sessionId', sessionId);
            this.resetPasswordExpiredLogin();
            getUserInfo()
              .then((res) => {
                if (res.error) {
                  throw new Error(res.error);
                }
                transaction(() => {
                  this.loggingin = false;
                  this.profileData = res.data;
                  this.authenticated = true;
                  this.isAdmin = isAdmin ? true : false;
                  this.sessionId = sessionId;
                  this.logoId = logoId;
                  this.portalRole = portalRole;
                  if (passwordExpired) {
                    this.passwordExpired = passwordExpired;
                    this.passNotChangedYet = passNotChangedYet;
                  } else {
                    this.passwordExpired = false;
                    this.passNotChangedYet = false;
                  }
                  this.loggingin = false;
                  this.initialized = true;
                });

                this.appState.initPortalConfig();
                loginSubject.next(this.username);
                this.username = '';
                this.password = '';
                this.initSessionPreservation(res.data.sessionTimeoutSec);
                if (passwordExpired) {
                  resolve('EXPIRED');
                }
                resolve('SUCCESS');
              })
              .catch((err) => {
                this.loggingin = false;
                toastSubject.next(err.message);

                this.initialized = true;
              });
          }
        })
        .catch((err) => {
          this.loggingin = false;
          if (err.message === 'You have entered an incorrect username and password combination. 5 failed attempts will result in your account being locked.') {
            resolve('BAD_CREDENTIALS');
            return;
          }
          if (err.message === 'Authentication code is empty') {
            resolve('TWO_FACTOR');
            return;
          }
          toastSubject.next(err.message);
        });
    });
    return loginPromise;
  };

  reloadUserInfo = () => {
    this.updatingProfile = true;
    getUserInfo()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.updatingProfile = false;
        this.profileData = res.data;
      })
      .catch((err) => {
        toastSubject.next(err.message);
        this.updatingProfile = false;
      });
  };

  initSessionPreservation = (timeout?: any) => {
    this.sessionExpirySub = registerInactivityDetection(
      timeout && typeof timeout === 'number'
        ? 9999999999999||timeout * 1000
        : INACTIVITY_LOGOUT_TIME_MILLISECONDS
    ).subscribe(() => {
      if (this.authenticated) {
        this.logoutInactive();
      }
    });
    this.sessionPreserveInterval = setInterval(
      () => {
        getUserInfo()
          .then((res) => {
            if (res.error) {
              throw new Error(res.error);
            }
          })
          .catch((err) => {});
      },
      timeout && typeof timeout === 'number'
        ? (timeout * 1000) / 2
        : SESSION_REFRESH_INTERVAL
    );
  };

  unsetPasswordExpired = () => {
    this.passwordExpired = false;
    this.passNotChangedYet = false;

    const userData = JSON.parse(
      window.localStorage.getItem('userData') || '{}'
    );

    window.localStorage.setItem(
      'userData',
      JSON.stringify({ ...userData, passwordExpired: false, passNotChangedYet: false })
    );
  };

  requestPasswordReset = (login: string) => {
    this.requestingPasswordReset = true;
    resetPassword(login)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.requestingPasswordReset = false;
        toastSubject.next(res.data);
      })
      .catch((err) => {
        this.requestingPasswordReset = false;
        toastSubject.next(err.message);
      });
  };

  resetPasswordConfirm = (newPassword: string, newPasswordConfirm: string) => {
    if (newPassword !== newPasswordConfirm) {
      toastSubject.next('The provided passwords do not match.');
    }
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  };

  confirmTwoFactor = () => {
    this.updatingProfile = true;
    return new Promise<void>((resolve, reject) => {
      const newUser = Object.assign({}, this.profileData, {
        secretCode: this.otpUserData!.key,
        validationCode: this.otpUserData?.verificationCode,
      });
      updateUserInfo(this.profileData!.login, newUser)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.updatingProfile = false;
          runInAction(() => {
            this.profileData!.twoFactorAuthenticationConfigured = true;
          });
          toastSubject.next(PopupEvent.TWO_FACTOR_SETUP);
          profileUpdatedSubject.next();
          resolve();
        })
        .catch((err) => {
          this.updatingProfile = false;
          toastSubject.next(err.message);
          reject();
        });
    });
  };

  updateDetails = (user: ProfileData) => {
    this.updatingProfile = true;
    return new Promise<void>((resolve, reject) => {
      updateUserInfo(user.login, user)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.profileData = user;
          this.updatingProfile = false;
          toastSubject.next(PopupEvent.PROFILE_UPDATED);
          profileUpdatedSubject.next();
          resolve();
        })
        .catch((err) => {
          this.updatingProfile = false;
          toastSubject.next(err.message);
          reject();
        });
    });
  };

  setupTwoFactor = async () => {
    const res = await setupTwoFactor().catch((e) => {
      toastSubject.next(e.message);
      throw new Error();
    });
    this.otpauth = res.data.second;
    this.otpUserData = res.data.first;
  };

  resetPasswordExpiredLogin = () => {
    this.passwordExpiredLogin = null;
    this.passNotChangedYet = false;
  };

  private doLogout() {
    if (this.sessionPreserveInterval) {
      clearInterval(this.sessionPreserveInterval);
    }
    if (this.sessionExpirySub) {
      this.sessionExpirySub.unsubscribe();
    }
    this.authenticated = false;
    window.localStorage.removeItem('userData');
    window.localStorage.removeItem('sessionId');
    this.logoId = null;
    this.sessionId = null;
    this.profileData = null;
    this.portalRole = null;
    logoutSubject.next();
  }
}

export const profileContext = createContext(new ProfileState(appState));
