import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { getCaseStatement } from '../../../server-api/api';
import { Case } from '../../../server-api/model';
import { actionsContext } from '../../../state/actionsState';
import { toastSubject } from '../../../state/rxjs';
import { formatNumber } from '../../../utils/formatNumber';
import { Input } from '../../common/input/Input';
import { InputSelect } from '../../common/input/InputSelect';
import { TextField } from '../../common/input/TextField';
import { validator } from '../../common/input/validator';
import { PopupEvent } from '../../common/popup/Popup';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import './ChangeDebt.css';
import { SelectInfo } from './selectinfo/SelectInfo';
import { ActionSubmit } from './submit/ActionSubmit';
import {useDots, useKeys} from '../../../utils/hooks';
import validate from 'validate.js';

export const ChangeDebtAmount: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);

    const formName = 'ChangeDebtModal';
    const [outstandingBalance, setOutstandingBalance] = useState<number | null>(
      null
    );

    const selectedCase: Case = AS.selectedCases.keys().next().value;

    useEffect(() => {
      if (outstandingBalance === null) {
        getCaseStatement(selectedCase.oneStepId.toString()).then((res) => {
          if (res.data) {
            setOutstandingBalance(res.data.debtBalanceWithSettlementSubTotal);
          } else {
            toastSubject.next('FAIL');
          }
        });
      }

      if (showModal) {
          AS.getDebtFeeElementsToChange(selectedCase.id)
          AS.getDebtAdjustTypes(selectedCase.id)
      }
    }, [outstandingBalance, selectedCase, AS, showModal]);

    const changeType = action((value: string) => {
      AS.debtAdjustType = value;
      if (outstandingBalance === null) {
        return;
      }
      if (value === 'decrease') {
        if (AS.debtAdjustAmount && AS.debtAdjustAmount !== '') {
          AS.debtAdjustAmount = Math.min(
            parseFloat(AS.debtAdjustAmount || '0.00'),
            outstandingBalance
          ).toFixed(2);
        }
      }
    });

    const changeFeeElement = action((value: string) => {
      AS.debtAdjustFeeElement = value;
    });

    const changeComment = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      AS.debtAdjustComments = e.currentTarget.value;
    });

    const changeAmount = action((e: React.FormEvent<HTMLInputElement>) => {
      if (outstandingBalance === null) {
        return;
      }

      if (
        AS.debtAdjustType === 'decrease' &&
        e.currentTarget.value.length > 0
      ) {
        AS.debtAdjustAmount = Math.min(
          parseFloat(e.currentTarget.value),
          outstandingBalance
        ).toString();
      } else {
        AS.debtAdjustAmount = e.currentTarget.value;
      }
    });

    const submit = useCallback(() => {
      if (AS.submitting) {
        return;
      }
      validator
        .validate(formName, true)
        .then(() =>
          AS.submitDebtChange()
            .then(() => {
              toastSubject.next(PopupEvent.CHANGE_DEBT_SUCCESS);
              closeModal();
            })
            .catch((err) => {
              toastSubject.next(err.message);
            })
        )
        .catch(() => 0);
    }, [AS, closeModal]);

    const formatAmount = () => {
      let amount = AS.debtAdjustAmount;

      if (amount && amount.length && amount.indexOf('.') === -1) {
        amount += '.00';
      }
      if (amount && amount.length && amount.indexOf('.') > -1) {
        amount = amount.replace(/^\.\d+$/g, '0' + amount);
        amount = amount.replace(/^\d+\.\d{1}$/g, amount + '0');
        amount = amount.replace(/^\d+\.$/g, amount + '00');
        amount = amount.replace(/^\.$/g, '0.00');
      }
      runInAction(() => {
        AS.debtAdjustAmount = amount;
        const input = document.querySelector('#debtAdjustAmount');
        if (input) {
          (input as any).rawValue = amount || '';
        }
      });
    };

    useKeys(['Enter'], [formatAmount], undefined, true);

    if (!selectedCase) {
      return null;
    }

    const loadingDots = useDots();

    return (
      <>
        <div>
          <SelectInfo />
          <h3>Details</h3>
          <p>
            <span style={{ marginRight: '10px' }}>Original debt amount:</span>
            <span>£{formatNumber(selectedCase.debtInitial)}</span>
          </p>

          <p>
            <span style={{ marginRight: '10px' }}>
              Outstanding debt balance:
            </span>
            <span>
              {outstandingBalance !== null ? (
                '£' + formatNumber(outstandingBalance || 0)
              ) : (
                <Spinner
                  style={{ height: '16px', verticalAlign: 'baseline' }}
                  small={true}
                />
              )}
            </span>
          </p>

          <p>(You cannot reduce the debt amount by more than this balance)</p>
          {AS.debtFeeElements && Object.keys(AS.debtFeeElements).length > 0 && (
              <div className="flex-group">
                  <span className="input-label">Fee element</span>
                  <InputSelect
                      width="grow"
                      formName={formName}
                      placeholder="..."
                      options={AS.debtFeeElements}
                      name="debtAdjustFeeElement"
                      selectedThing={AS.debtAdjustFeeElement}
                      onChange={changeFeeElement}
                      validateFunc={() =>
                          validate.single(AS.debtAdjustFeeElement, {
                              presence: {
                                  allowEmpty: false,
                                  message: 'Select fee element',
                              },
                          })
                      }
                  />
              </div>
          )}
            <div className="flex-group">
            <InputSelect
              width="compact"
              formName={formName}
              placeholder={AS.debtAdjustTypes ? '...' : 'Loading' + loadingDots}
              options={AS.debtAdjustTypes ? AS.debtAdjustTypes : {}}
              name="debtAdjustType"
              selectedThing={AS.debtAdjustType}
              onChange={changeType}
              validateFunc={() =>
                validate.single(AS.debtAdjustType, {
                  presence: {
                    allowEmpty: false,
                    message: 'Select type',
                  },
                })
              }
            />
            <span className="input-label">amount by</span>
            <Input
              properties={{ 'aria-label' : 'Enter debt change amount' }}
              width="grow"
              formName={formName}
              validateFunc={() =>
                validate.single(AS.debtAdjustAmount, {
                  presence: { allowEmpty: false },
                  numericality: {
                    strict: true,
                    greaterThan: 0,
                    notGreaterThan: 'A positive amount is required',
                  },
                })
              }
              options={{
                rawValueTrimPrefix: true,
                numeral: true,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'thousand',
                prefix: '£',
                noImmediatePrefix: true,
              }}
              placeholder="£0.00"
              onFocusOut={formatAmount}
              value={AS.debtAdjustAmount}
              name="debtAdjustAmount"
              onChange={changeAmount}
            />
          </div>
          <h3>Comments</h3>
          <TextField
            formName={formName}
            validateFunc={() =>
              validate.single(AS.debtAdjustComments, {
                presence: { allowEmpty: false },
              })
            }
            placeholder="Add a comment"
            name="debtAdjustComments"
            onChange={changeComment}
            value={AS.debtAdjustComments}
            className="wide"
          />
        </div>
        <ActionSubmit
          submitting={AS.submitting}
          cancel={closeModal}
          submit={submit}
          submitText="Change debt"
        />
      </>
    );
  }
);
