import { action, decorate, observable, set } from 'mobx';
import { Moment } from 'moment';
import queryString, { ParsedQuery } from 'query-string';
import { createContext } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import {
  CASE_VIEW_STEP,
  COMMON_DEBOUNCE_DURATION,
  DEBOUNCE_AFTER_ACTION_DURATION,
  DEBTOR_VIEW_STEP,
  REGEXPS,
  SHORT_DEBOUNCE_DURATION,
} from '../../config';
import {
  getCaseFilesContracts,
  requestDebtorsByCasesIds,
  requestDebtorsByIds,
  requestSearchCases,
  requestSearchCasesCount,
  requestSearchDebtors,
  requestSearchDebtorsCount as requestDebtorsSearchCount,
} from '../../server-api/api';
import {
  Categories,
  FilterInterface,
  SavedSearch,
  SearchCaseEntryResponse,
  SearchEntryResponse,
  ServerResponse,
} from '../../server-api/model';
import { constructFiltersQuery } from '../../utils/query';
import { wait } from '../../utils/wait';
import { appState } from '../appState';
import {
  actionSuccessEvent,
  closedToggleEvent,
  logoutEvent,
  modalCloseEvent,
  SHOW_CLOSED_SWITCH_SRC,
  toastSubject,
  updateUrlSubject,
} from '../rxjs';
import SearchedCasesState, { caseSortSubject } from './searchedCasesState';
import SearchedDebtorsState, {
  debtorSortSubject,
} from './searchedDebtorsState';
import { checkCazeClosed } from './utils';

export enum SEARCH_TYPE {
  NORMAL = 'NORMAL',
  SUMMARY = 'SUMMARY',
  BOOKMARK = 'BOOKMARK',
  NONE = 'NONE',
}

enum filterNameMapping {
  clientRef = 'Client ref',
  jbwRef = 'JBW_REF', // replaced dynamically
  summonsNo = 'Summons #',
  vrm = 'VRM',
  batch = 'Batch',
  closed = 'Open/Closed',
  status = 'Status',
  stage = 'Stage',
  onHold = 'Hold',
  activeAgent = 'Agent',
  cga = 'Controlled',
  lastName = 'Last name',
  firstName = 'First name',
  postcode = 'Postcode',
  isCompany = 'Company',
  vulnerable = 'Vulnerable',
  warning = 'Warning',
  brokenArrangement = 'Broken arrangements',
  liveCasesCountFrom = 'Open cases from',
  liveCasesCountTo = 'Open cases to',
  warrantFrom = 'Warrant from',
  warrantTo = 'Warrant to',
  offenceFrom = 'Offence from',
  offenceTo = 'Offence to',
  dueFrom = 'Due/Up from',
  dueTo = 'Due/Up to',
  referredFrom = 'Referred from',
  referredTo = 'Referred to',
  contract = 'Contracts',
  claimantName = 'Claimant name',
}

enum pasteTypeNameMapping {
  vrm = 'VRMs',
  clientRef = 'Client Refs',
  surname = 'Surnames',
  postcode = 'Postcodes',
}

export interface FilterChip {
  name: string;
  value?: boolean | string | object | number;
  key: string;
  subkey?: string;
}

const chipRemovedSubject = new Subject<void>();

export class SearchState {
  isSearchScreen = false;
  filterOptionContracts: Categories = {};
  filterOptionStatuses: { [name: string]: string } = {
    Arrangement: 'A',
    Live: 'L',
    Cancelled: 'C',
    Successful: 'S',
  };
  filterOptionStages = {
    Compliance: 'Compliance',
    Enforcement: 'Enforcement',
    Sale: 'Sale',
  };
  filterOptionHolds: { [name: string]: string } = {
    Yes: 'yes',
    No: 'no',
    Client: 'client',
    Internal: 'internal',
  };
  filtersOpenClosed = { Open: 'open', Closed: 'closed', All: 'both' };

  filters: FilterInterface = {
    caseDetails: {
      batch: undefined,
      clientRef: undefined,
      summonsNo: undefined,
      vrm: undefined,
      jbwRef: undefined,
      claimantName: undefined,
    },
    caseStatus: {
      activeAgent: undefined,
      cga: undefined,
      closed: undefined,
      onHold: undefined,
      stage: undefined,
      status: undefined,
    },
    customerDetails: {
      brokenArrangement: undefined,
      firstName: undefined,
      isCompany: undefined,
      lastName: undefined,
      liveCasesCountFrom: undefined,
      liveCasesCountTo: undefined,
      postcode: undefined,
      vulnerable: undefined,
      warning: undefined,
    },
    dates: {
      dueFrom: undefined,
      dueTo: undefined,
      offenceFrom: undefined,
      offenceTo: undefined,
      referredFrom: undefined,
      referredTo: undefined,
      warrantFrom: undefined,
      warrantTo: undefined,
    },
    pasteText: undefined,
    pasteType: 'clientRef',
    contracts: [],
    bookmarks: {
      cases: [],
      debtors: [],
    },
  };

  searchedDebtorsState = new SearchedDebtorsState();
  searchedCasesState = new SearchedCasesState();

  inputValue = '';
  lastQuery = '';
  chips: string[] = [];
  filterChips: Map<string, FilterChip> = new Map();
  searching = false;
  loadingMore = false;
  caseView = false;
  emptyQuery = false;
  searchType: SEARCH_TYPE = SEARCH_TYPE.NONE;
  bookmarkCasesIds: null | string[] = null;
  bookmarkDebtorsIds: null | string[] = null;
  summaryCasesIds: null | string[] = null;

  @observable
  gettingContracts = false;

  constructor() {
    modalCloseEvent
      .pipe(filter((id) => id === 'FiltersModal'))
      .pipe(debounceTime(400))
      .subscribe((modalId) => {
        if (this.searchType === SEARCH_TYPE.NONE) {
          this.resetFilters();
        }
      });
    debtorSortSubject
      .asObservable()
      .pipe(debounceTime(SHORT_DEBOUNCE_DURATION))
      .subscribe(() => {
        if (this.searchType === SEARCH_TYPE.NORMAL) {
          this.runNormalSearchDebtors();
        }
      });
    caseSortSubject
      .asObservable()
      .pipe(debounceTime(SHORT_DEBOUNCE_DURATION))
      .subscribe(() => {
        if (this.searchType === SEARCH_TYPE.NORMAL) {
          this.runNormalSearchCases();
        }
      });
    actionSuccessEvent
      .pipe(debounceTime(DEBOUNCE_AFTER_ACTION_DURATION))
      .subscribe({
        next: () => {
          if (
            this.searchType === SEARCH_TYPE.BOOKMARK &&
            this.bookmarkCasesIds
          ) {
            this.runCaseBookmarkSearch(this.bookmarkCasesIds);
          } else if (
            this.searchType === SEARCH_TYPE.BOOKMARK &&
            this.bookmarkDebtorsIds
          ) {
            this.runDebtorBookmarkSearch(this.bookmarkDebtorsIds);
          } else if (
            this.searchType === SEARCH_TYPE.NORMAL &&
            (this.chips.length !== 0 || this.filterChips.size !== 0)
          ) {
            this.runSearch();
          }
        },
      });

    chipRemovedSubject
      .pipe(debounceTime(COMMON_DEBOUNCE_DURATION))
      .subscribe(() => {
        if (this.chips.length || this.filterChips.size) {
          this.runSearch();
        }
      });

    closedToggleEvent
      .pipe(filter(({ source }) => source === SHOW_CLOSED_SWITCH_SRC.SEARCH))
      .subscribe(() => {
        if (
          !appState.showClosed &&
          this.filters.caseStatus.closed === 'closed' &&
          this.searchType === SEARCH_TYPE.NORMAL
        ) {
          this.searchedCasesState.reset();
          this.searchedDebtorsState.reset();
        } else if (
          (this.filters.caseStatus.closed === 'open' ||
            this.filters.caseStatus.closed === 'both') &&
          this.searchType === SEARCH_TYPE.NORMAL
        ) {
          if (!this.caseView) {
            this.searchedCasesState.reset();
          } else {
            this.runNormalSearchCases();
          }
          return;
        } else if (
          this.chips.length > 0 ||
          (this.filterChips.size > 0 && this.searchType === SEARCH_TYPE.NORMAL)
        ) {
          this.runSearch();
        }
      });
    logoutEvent.subscribe(() => {
      this.clearSearch();
    });
  }

  getContracts = () => {
    return new Promise<void>((resolve) => {
      this.gettingContracts = true;
      getCaseFilesContracts(false)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.filterOptionContracts = res.data;
          this.gettingContracts = false;
          resolve();
          if (Object.entries(this.filterOptionContracts).length > 0) {
            // this.selectedContract = Object.entries(this.contracts)[0][1];
          }
        })
        .catch((err) => {
          this.gettingContracts = false;
          toastSubject.next(err.message);
        });
    });
  };

  setLastQuery = (query: string) => {
    this.lastQuery = query;
  };

  toggleCaseView = (caseView?: boolean) => {
    if (caseView !== undefined) {
      this.caseView = caseView;
    } else {
      this.caseView = !this.caseView;
    }
    if (
      this.caseView &&
      !this.searchedCasesState.hasSearched &&
      this.searchType === SEARCH_TYPE.NORMAL
    ) {
      this.runNormalSearchCases();
    } else if (
      !this.caseView &&
      !this.searchedDebtorsState.hasSearched &&
      this.searchType === SEARCH_TYPE.NORMAL
    ) {
      this.runNormalSearchDebtors();
    }
  };

  tokenizeQuery = (query: string) => {
    query = query.trim();
    // uncomment to enable wildcard validation for common search box
    // if (query[0] === '*') {
    //   throw Error(
    //     'The wildcard (“*”) character cannot be used at the beginning of a search string.'
    //   );
    // }
    if (query.length < 2) {
      throw Error('A minimum of 2 characters are required to run a search.');
    }
    const newChips = query.split(',');
    const trimmedChips: string[] = [];
    for (const chip of newChips) {
      const trimmedChip = chip.trim().replace(/(\s+)/g, ' ');
      // uncomment to enable wildcard validation for common search box
      // if (trimmedChip[0] === '*') {
      //   throw Error(
      //     'The wildcard (“*”) character cannot be used at the beginning of a search string.'
      //   );
      // }
      if (trimmedChip.length === 1) {
        throw Error('Keywords must have at least 2 characters.');
      }
      trimmedChips.push(trimmedChip);
    }
    return trimmedChips.filter((chip) => chip.replace(/\s/g, '').length);
  };

  changeInput = (input: string) => {
    if (input === ',') {
      return;
    }
    this.inputValue = input;
  };

  removeChip = (index: number) => {
    this.chips.splice(index, 1);
    updateUrlSubject.next();
    chipRemovedSubject.next();
    if (!this.chips.length && !this.filterChips.size) {
      const savedInput = this.inputValue;
      this.clearSearch();
      this.inputValue = savedInput;
      return;
    }
  };

  removeFilterChip = async (chip: FilterChip) => {
    if (chip.key && chip.subkey) {
      switch (chip.subkey) {
        case 'closed':
          if (chip.value === 'Closed' || chip.value === 'All') {
            appState.toggleShowClosed({
              state: false,
              src: SHOW_CLOSED_SWITCH_SRC.SEARCH,
              dontAnnounce: true,
            });
          }
          break;
        default:
          break;
      }
      this.filters[chip.key][chip.subkey] = undefined;
    } else if (chip.key) {
      switch (chip.key) {
        case 'contracts':
          this.filters[chip.key] = [];
          break;
        case 'bookmarks':
          this.resetBookmarks();
          break;
        default:
          this.filters[chip.key] = undefined;
          break;
      }
    }
    await this.prepareFilterChips();
    updateUrlSubject.next();
    chipRemovedSubject.next();
    if (!this.chips.length && !this.filterChips.size) {
      const savedInput = this.inputValue;
      this.clearSearch();
      this.inputValue = savedInput;
      return;
    }
  };

  removeAllChips = () => {
    this.filterChips.clear();
    this.chips = [];
  };

  getChipsFromSearchString = (searchString: string) => {
    try {
      this.chips = [...this.chips, ...this.tokenizeQuery(searchString)];
      return true;
    } catch (error) {
      if (
        !this.inputValue.length &&
        (this.chips.length || this.filterChips.size)
      ) {
        return true;
      }
      toastSubject.next(error.message);
      return false;
    }
  };

  getChipsFromQuery = async (
    queries: ParsedQuery<string>,
    dontSetChips?: boolean
  ) => {
    try {
      if (queries.q) {
        try {
          this.chips = [...this.tokenizeQuery(queries.q as string)];
        } catch (err) {}
      } else {
        this.chips = [];
      }
      if (queries.contracts && typeof queries.contracts === 'string') {
        this.filters.contracts = queries.contracts.split(',');
      }
      if (queries.p && typeof queries.p === 'string') {
        this.filters.pasteType = queries.p;
      }
      if (queries.t && typeof queries.t === 'string') {
        this.filters.pasteText = queries.t;
      }
      if (queries.bd && typeof queries.bd === 'string') {
        const validIds: string[] = [];
        queries.bd.split(',').forEach((debtorId) => {
          if (debtorId.match(REGEXPS.UUID)) {
            validIds.push(debtorId);
          }
        });
        this.filters.bookmarks.debtors = validIds;
      }
      if (queries.bc && typeof queries.bc === 'string') {
        const validIds: string[] = [];
        queries.bc.split(',').forEach((caseId) => {
          if (caseId.match(REGEXPS.UUID)) {
            validIds.push(caseId);
          }
        });
        this.filters.bookmarks.cases = validIds;
      }
      Object.entries(queries).forEach(([queryKey, queryValue]) => {
        Object.entries(this.filters).forEach(([categoryKey, categoryValue]) => {
          if (
            categoryKey === 'contracts' ||
            categoryKey === 'bookmarks' ||
            categoryKey === 'pasteText' ||
            categoryKey === 'pasteType'
          ) {
            return;
          }
          Object.entries(categoryValue).forEach(([key, value]) => {
            if (key === queryKey) {
              let parsedValue: any;

              if (categoryKey === 'dates') {
                if (queryValue && !isNaN(parseInt(queryValue as string))) {
                  parsedValue = parseInt(queryValue as string);
                } else {
                  return;
                }
              } else if (this.keyIsBoolean(key)) {
                if (queryValue === 'true') {
                  parsedValue = true;
                } else if (queryValue === 'false') {
                  parsedValue = false;
                } else {
                  return;
                }
              } else {
                parsedValue = queryValue;
              }
              this.filters[categoryKey][key] = parsedValue;
            }
          });
        });
      });
      if (dontSetChips) {
        return true;
      }
      return true;
    } catch (error) {
      toastSubject.next(error.message);
      return false;
    }
  };

  private keyIsBoolean = (key: string) => {
    let isBoolean = false;
    const booleanKeys = [
      'activeAgent',
      'cga',
      'isCompany',
      'vulnerable',
      'warning',
      'brokenArrangement',
    ];
    booleanKeys.forEach((filterKey) => {
      if (key === filterKey) {
        isBoolean = true;
      }
    });
    return isBoolean;
  };

  private keyIsDate = (key: string) => {
    let isDate = false;
    Object.keys(this.filters.dates).forEach((dateKey) => {
      if (key === dateKey) {
        isDate = true;
      }
    });
    return isDate;
  };

  syncGlobalToggle = () => {
    return new Promise<void>((resolve) => {
      if (
        this.filters.caseStatus.closed === 'closed' ||
        this.filters.caseStatus.closed === 'both'
      ) {
        appState.toggleShowClosed({
          state: true,
          src: SHOW_CLOSED_SWITCH_SRC.SEARCH,
          dontAnnounce: true,
        });
      }
      resolve();
    });
  };

  private getPasteArray = (surnameToo?: true) => {
    const arr: string[] = [];
    if (!this.filters.pasteText) {
      return arr;
    }
    const commaSplit = this.filters.pasteText.trim().split(',');
    commaSplit.forEach((eleComma) => {
      const newLineSplit = eleComma.trim().split('\n');
      newLineSplit.forEach((eleNewLine) => {
        arr.push(eleNewLine);
      });
    });
    if (this.filters.pasteType !== 'surname' || surnameToo) {
      arr.forEach((postcode, index) => {
        arr[index] = postcode.replace(/\s/g, '').toUpperCase();
      });
    }
    return arr;
  };

  private constructFilterRequestParam = () => {
    const body: { [name: string]: string | number | boolean | string[] } = {};
    Object.entries(this.filters).forEach(([categoryKey, categoryValue]) => {
      if (typeof categoryValue !== 'object') {
        return;
      }
      if (categoryKey === 'bookmarks') {
        return;
      }
      Object.entries(categoryValue as Object).forEach(([key, value]) => {
        if (typeof value === 'object' && 'calendar' in value) {
          body[categoryKey + '_' + key] = (value as Moment).valueOf();
          return;
        }
        if (value !== undefined && value !== '') {
          body[categoryKey + '_' + key] = value;
        }
      });
    });
    if (this.filters.pasteText && this.filters.pasteType) {
      body['paste_' + this.filters.pasteType] = this.getPasteArray(true);
    }
    if (this.filters.contracts.length) {
      body.contracts = this.filters.contracts;
    }
    if (this.filters.caseStatus.closed === 'closed') {
      body.caseStatus_closed = true;
    }
    if (this.filters.caseStatus.closed === 'open') {
      body.caseStatus_closed = false;
    }
    if (this.filters.caseStatus.closed === 'both') {
      delete body.caseStatus_closed;
    }
    if (
      (this.filters.caseStatus.closed === 'both' ||
        this.filters.caseStatus.closed === undefined) &&
      !appState.showClosed
    ) {
      body.caseStatus_closed = false;
    }

    return body;
  };

  runSearch = () => {
    this.prevSearchCleanup();
    this.syncGlobalToggle();
    this.searchType = SEARCH_TYPE.NORMAL;
    if (this.filters.bookmarks.cases.length) {
      this.runCaseBookmarkSearch(this.filters.bookmarks.cases);
    } else if (this.filters.bookmarks.debtors.length) {
      this.runDebtorBookmarkSearch(this.filters.bookmarks.debtors);
    } else if (this.caseView) {
      this.runNormalSearchCases();
    } else {
      this.runNormalSearchDebtors();
    }
  };

  @action
  private runNormalSearchDebtors = () => {
    const constructedFilters = this.constructFilterRequestParam();
    if (this.chips.length === 0 && this.filterChips.size === 0) {
      this.emptyQuery = true;
      return;
    }

    this.searching = true;
    this.searchedDebtorsState.onBeginSearch();

    requestDebtorsSearchCount(this.chips.join(','), constructedFilters)
      .then(
        action((res) => {
          if (typeof res.data !== 'number') {
            throw Error('Failed to get search count');
          }
          this.searchedDebtorsState.onCountReceived(res.data);
        })
      )
      .catch((err) => {
        toastSubject.next(err.message);
      });
    requestSearchDebtors(
      this.chips.join(','),
      this.searchedDebtorsState.apiStep,
      DEBTOR_VIEW_STEP,
      this.searchedDebtorsState.debtorSorter.sortBy,
      !this.searchedDebtorsState.debtorSorter.sortAscending,
      constructedFilters
    )
      .then(
        action((res: ServerResponse<SearchEntryResponse[]>) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.searchedDebtorsState.onResultsReceived(res.data);
          this.searching = false;
        })
      )
      .catch((err) => {
        this.searching = false;
        toastSubject.next(err.message);
      });
  };

  @action
  runNormalSearchDebtorsMore = () => {
    this.loadingMore = true;
    this.searchedDebtorsState.onBeginSearchMore();
    const constructedFilters = this.constructFilterRequestParam();
    requestSearchDebtors(
      this.chips.join(','),
      this.searchedDebtorsState.apiStep,
      DEBTOR_VIEW_STEP,
      this.searchedDebtorsState.debtorSorter.sortBy,
      !this.searchedDebtorsState.debtorSorter.sortAscending,
      constructedFilters
    )
      .then(
        action((res: ServerResponse<SearchEntryResponse[]>) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.searchedDebtorsState.onMoreResultsReceived(res.data);
          this.loadingMore = false;
        })
      )
      .catch((err) => {
        this.loadingMore = false;
        toastSubject.next(err.message);
      });
  };

  @action
  private runNormalSearchCases = () => {
    const constructedFilters = this.constructFilterRequestParam();
    if (this.chips.length === 0 && this.filterChips.size === 0) {
      this.emptyQuery = true;
      return;
    }
    this.searching = true;
    this.searchedCasesState.onBeginSearch();
    requestSearchCasesCount(this.chips.join(','), constructedFilters)
      .then((res) => {
        if (typeof res.data !== 'number') {
          throw Error('Failed to get search count');
        }
        this.searchedCasesState.onCountReceived(res.data);
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });

    requestSearchCases(
      this.chips.join(','),
      this.searchedCasesState.apiStep,
      CASE_VIEW_STEP,
      this.searchedCasesState.caseSorter.sortBy,
      !this.searchedCasesState.caseSorter.sortAscending,
      constructedFilters
    )
      .then(
        action((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.searchedCasesState.onResultsReceived(res.data);
          this.searching = false;
        })
      )
      .catch((err) => {
        this.searching = false;
        toastSubject.next(err.message);
      });
  };

  @action
  runNormalSearchCasesMore = () => {
    this.loadingMore = true;
    this.searchedCasesState.onBeginSearchMore();
    return new Promise<void>((resolve, reject) => {
      const constructedFilters = this.constructFilterRequestParam();
      requestSearchCases(
        this.chips.join(','),
        this.searchedCasesState.apiStep,
        CASE_VIEW_STEP,
        this.searchedCasesState.caseSorter.sortBy,
        !this.searchedCasesState.caseSorter.sortAscending,
        constructedFilters
      )
        .then(
          action((res) => {
            if (res.error) {
              throw new Error(res.error);
            }
            this.searchedCasesState.onMoreResultsReceived(res.data);
            this.loadingMore = false;
            resolve();
          })
        )
        .catch((err) => {
          this.loadingMore = false;
          toastSubject.next(err.message);
        });
    });
  };

  @action
  private runCaseBookmarkSearch = async (casesIds: string[]) => {
    this.prevSearchCleanup();
    this.bookmarkCasesIds = casesIds;
    this.searchType = SEARCH_TYPE.BOOKMARK;
    this.searching = true;
    try {
      const debtorsResponse = await requestDebtorsByCasesIds(
        casesIds,
        0,
        9000, // user probably won't be manually ticking over 9000 bookmarks
        this.searchedDebtorsState.debtorSorter.sortBy,
        !this.searchedDebtorsState.debtorSorter.sortAscending
      );

      if (debtorsResponse.error) {
        throw Error(debtorsResponse.error);
      }
      this.searchedDebtorsState.onResultsReceived(debtorsResponse.data);
      this.searchedDebtorsState.onCountReceived(debtorsResponse.data.length);
      // get selected bookmark cases from debtor response,
      // this way we don't need to run another api query to get just the cases
      const receivedCases = debtorsResponse.data.reduce<
        SearchCaseEntryResponse[]
      >((cases, debtor) => {
        const matchingCases = debtor.cases.reduce<SearchCaseEntryResponse[]>(
          (prev, next) => {
            if (!casesIds.includes(next.id)) {
              return prev;
            }
            // flip toggle if one of the bookmarked cases is closed
            if (checkCazeClosed(next.status)) {
              appState.toggleShowClosed({
                state: true,
                src: SHOW_CLOSED_SWITCH_SRC.FAVLIST,
                dontAnnounce: true,
              });
            }
            return [
              ...prev,
              {
                vulnerableFlag: debtor.vulnerableFlag,
                warningFlag: debtor.warningFlag,
                cases: next,
              },
            ];
          },
          []
        );
        return [...cases, ...matchingCases];
      }, []);
      this.searchedCasesState.onResultsReceived(receivedCases);
      this.searchedCasesState.onCountReceived(receivedCases.length);
      this.searching = false;
    } catch (error) {
      this.searching = false;
      toastSubject.next(error.message);
    }
  };

  private runDebtorBookmarkSearch = (list: string[]) => {
    this.prevSearchCleanup();
    this.bookmarkDebtorsIds = list;
    this.searching = true;
    this.searchType = SEARCH_TYPE.BOOKMARK;

    requestDebtorsByIds(list, 0, 9000)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.searchedDebtorsState.onResultsReceived(res.data);
        this.searchedDebtorsState.onCountReceived(res.data.length);
        const receivedCases = res.data.reduce<SearchCaseEntryResponse[]>(
          (cases, debtor) => {
            const matchingCases = debtor.cases.reduce<
              SearchCaseEntryResponse[]
            >((prev, next) => {
              return [
                ...prev,
                {
                  vulnerableFlag: debtor.vulnerableFlag,
                  warningFlag: debtor.warningFlag,
                  cases: next,
                },
              ];
            }, []);
            return [...cases, ...matchingCases];
          },
          []
        );
        this.searchedCasesState.onResultsReceived(receivedCases);
        this.searchedCasesState.onCountReceived(receivedCases.length);
        this.searching = false;
      })
      .catch((err) => {
        this.searching = false;
        toastSubject.next(err.message);
      });
  };

  private prevSearchCleanup = () => {
    this.searchedCasesState.reset();
    this.searchedDebtorsState.reset();

    this.bookmarkDebtorsIds = null;
    this.bookmarkCasesIds = null;
    this.summaryCasesIds = null;

    this.emptyQuery = false;
  };

  clearSearch = () => {
    this.prevSearchCleanup();
    this.inputValue = '';
    this.searchType = SEARCH_TYPE.NONE;
    this.lastQuery = '';
    this.emptyQuery = false;
    this.loadingMore = false;
    this.searching = false;
    this.chips = [];
    this.filterChips.clear();
    this.resetFilters();
  };

  resetFilters() {
    Object.entries(this.filters).forEach(([categoryKey, categoryValue]) => {
      if (typeof categoryValue !== 'object') {
        return;
      }
      Object.entries(categoryValue as Object).forEach(([key, value]) => {
        set(this.filters[categoryKey], key, undefined);
      });
    });
    set(this.filters, 'pasteText', undefined);
    set(this.filters, 'pasteType', 'clientRef');
    set(this.filters, 'contracts', []);
    set(this.filters, 'contracts', []);
    this.resetBookmarks();
  }

  resetBookmarks = () => {
    set(this.filters.bookmarks, 'cases', []);
    set(this.filters.bookmarks, 'debtors', []);
  };

  submitSearch = async () => {
    const valid = this.getChipsFromSearchString(this.inputValue);
    if (valid) {
      this.inputValue = '';
      this.runSearch();
      updateUrlSubject.next();
    }
  };

  submitSearchFiltered = async () => {
    const valid = await this.prepareFilterChips();
    if (valid) {
      this.runSearch();
      updateUrlSubject.next();
    }
    return valid;
  };

  prepareFilterChips = async () => {
    try {
      await this.handleContractChip();
      this.handlePasteChip();
      this.handleBookmarksChip();
      Object.entries(this.filters).forEach(([key, category]) => {
        Object.entries(category || {}).forEach(([subkey, value]) => {
          let valueName = value as
            | string
            | number
            | boolean
            | object
            | undefined;
          switch (subkey) {
            case 'status':
              valueName = Object.keys(this.filterOptionStatuses).find(
                (targetKey) => {
                  return this.filterOptionStatuses[targetKey] === value;
                }
              );
              break;
            case 'stage':
              valueName = Object.keys(this.filterOptionStages).find(
                (targetKey) => {
                  return this.filterOptionStages[targetKey] === value;
                }
              );
              break;
            case 'onHold':
              valueName = Object.keys(this.filterOptionHolds).find(
                (targetKey) => {
                  return this.filterOptionHolds[targetKey] === value;
                }
              );
              break;
            case 'closed':
              valueName = Object.keys(this.filtersOpenClosed).find(
                (targetKey) => {
                  return this.filtersOpenClosed[targetKey] === value;
                }
              );
              break;
          }
          const name = filterNameMapping[subkey] as string;
          if (name && valueName !== undefined && valueName !== '') {
            if (typeof value === 'string' && value[0] === '*') {
              throw Error(
                'The wildcard (“*”) character cannot be used at the beginning of a search string.'
              );
            }
            this.filterChips.set(name, {
              name,
              value: valueName,
              key,
              subkey,
            });
          } else if (name) {
            this.filterChips.delete(name);
          }
        });
      });
      return true;
    } catch (error) {
      toastSubject.next(error.message);
      return false;
    }
  };

  handleBookmarksChip = () => {
    if (
      this.filters.bookmarks.cases.length ||
      this.filters.bookmarks.debtors.length
    ) {
      this.filterChips.set('Bookmarks', {
        name: 'Bookmarks',
        value: true,
        key: 'bookmarks',
      });
    } else {
      this.filterChips.delete('Bookmarks');
    }
  };

  handlePasteChip = () => {
    Object.values(pasteTypeNameMapping).forEach((paste) => {
      this.filterChips.delete(paste);
    });

    const pasteArr = this.getPasteArray();
    if (this.filters.pasteText && this.filters.pasteType) {
      let chipName = pasteTypeNameMapping[this.filters.pasteType];

      this.filterChips.set(chipName, {
        name: chipName,
        value: pasteArr.join(', '),
        key: 'pasteText',
      });
    }
  };

  handleContractChip: () => Promise<any> = () => {
    if (!this.filters.contracts.length) {
      this.filterChips.delete('Contracts');
      return new Promise<void>((resolve) => resolve());
    }

    if (!Object.keys(this.filterOptionContracts).length) {
      return wait(400).then(() => this.handleContractChip());
    }

    return new Promise<void>((resolve) => {
      let label = '';
      this.filters.contracts.forEach((contract, index) => {
        const contractName = Object.keys(this.filterOptionContracts).find(
          (element, index, object) => {
            let checkName = false;
            if (this.filterOptionContracts[element] === contract) {
              checkName = true;
            }
            return checkName;
          }
        );
        if (!contractName) {
          this.filters.contracts.splice(index, 1);
          return;
        }
        label += contractName + ', ';
      });
      label = label.slice(0, -2);

      this.filterChips.set('Contracts', {
        name: 'Contracts',
        value: label,
        key: 'contracts',
      });
      resolve();
    });
  };

  restoreSavedFilters = (search: SavedSearch) => {
    this.resetFilters();
    this.getChipsFromQuery(queryString.parse(search.searchString), true);
  };

  generateUrlFromChips = () => {
    let totalQuery = '';
    let searchQuery = '';

    if (this.chips.length > 0) {
      searchQuery += 'q=' + this.chips.join(',') + '&';
    }

    const filtersQuery = constructFiltersQuery(this.filters);

    let bookmarksQuery = '';
    if (searchQuery.length || filtersQuery.length) {
      this.resetBookmarks();
    } else {
      if (this.filters.bookmarks.cases.length) {
        const validIds: string[] = [];
        this.filters.bookmarks.cases.forEach((caseId) => {
          if (caseId.match(REGEXPS.UUID)) {
            validIds.push(caseId);
          }
        });
        bookmarksQuery = 'bc=' + validIds.join(',');
        this.toggleCaseView(true);
      }
      if (this.filters.bookmarks.debtors.length) {
        const validIds: string[] = [];
        this.filters.bookmarks.debtors.forEach((caseId) => {
          if (caseId.match(REGEXPS.UUID)) {
            validIds.push(caseId);
          }
        });
        bookmarksQuery = 'bd=' + validIds.join(',');
        this.toggleCaseView(false);
      }
    }

    if (filtersQuery.length) {
      totalQuery = searchQuery + filtersQuery;
    } else if (searchQuery.length) {
      totalQuery = searchQuery.slice(0, -1);
    } else if (bookmarksQuery.length) {
      totalQuery = bookmarksQuery;
    }

    return totalQuery;
  };
}

decorate(SearchState, {
  searching: observable,
  loadingMore: observable,
  filters: observable,
  filterOptionContracts: observable,
  inputValue: observable,
  lastQuery: observable,
  chips: observable,
  filterChips: observable,
  caseView: observable,
  emptyQuery: observable,
  searchType: observable,
  filtersOpenClosed: observable,
  filterOptionHolds: observable,
  filterOptionStatuses: observable,
  filterOptionStages: observable,
  isSearchScreen: observable,
});

export const searchState = new SearchState();
export const searchContext = createContext(searchState);
