import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { USER_ROLE } from '../../server-api/model';
import { actionsContext } from '../../state/actionsState';
import { modalContext } from '../../state/modalState';
import { profileContext } from '../../state/profileState';
import { toastSubject } from '../../state/rxjs';
import { formatNumber } from '../../utils/formatNumber';
import { Actions } from '../common/actions';
import { Button } from '../common/button/Button';

interface Props {}

const ActionsSection = observer((props: Props) => {
  const ACS = useContext(actionsContext);
  const PFS = useContext(profileContext);
  const actions = new Actions(useContext(modalContext)).getActions();

  useEffect(() => {
    if (!ACS.gotCategories) {
      ACS.getAllCategories();
    }
  }, [ACS.gotCategories]);

  const getTotalBalance = () => {
    if (ACS.selectedCases.size) {
      let total = 0;
      Array.from(ACS.selectedCases.keys()).forEach((caze) => {
        total += caze.debtAmount;
      });
      return formatNumber(total);
    } else if (ACS.selectedDebtors.size) {
      let total = 0;
      Array.from(ACS.selectedDebtors.keys()).forEach((debtor) => {
        debtor.cases.forEach((caze) => {
          total += caze.debtAmount;
        });
        //  total += debtor.debtBalance || 0;
      });
      return formatNumber(total);
    }
    return formatNumber(0);
  };

  const getActionsText = () => {
    const numberSelectedCases = ACS.selectedCases.size;
    const numberSelectedDebtors = ACS.selectedDebtors.size;
    if (numberSelectedCases === 0 && numberSelectedDebtors === 0) {
      return <>No items selected.</>;
    }
    if (numberSelectedCases !== 0) {
      return (
        <>
          {numberSelectedCases} case
          {numberSelectedCases > 1 ? 's' : ''} selected
        </>
      );
    } else {
      return (
        <>
          {numberSelectedDebtors} customer
          {numberSelectedDebtors > 1 ? 's' : ''} selected
        </>
      );
    }
  };

  return (
    <>
      <h2 className='actions-header'>
        <strong>Actions</strong>
        <span className='selected-things'>{getActionsText()}</span>
        {ACS.selectedCases.size || ACS.selectedDebtors.size ? (
          <span className='selected-balance'>£{getTotalBalance()}</span>
        ) : null}
      </h2>
      <div className={'action-buttons '}>
        {actions.map((action, index) => {
          const actionCheck = ACS.checkActions(action.scope);
          return (
            <Button
              key={'button-' + index}
              disabled={!action.roles.includes(PFS.portalRole as USER_ROLE) || !actionCheck.success}
              mode='success'
              onClick={() => {
                if (actionCheck.success) {
                  action.action();
                } else if (actionCheck.error) {
                  toastSubject.next(actionCheck.error);
                }
              }}
            >
              {action.name}
            </Button>
          );
        })}
      </div>
    </>
  );
});

export default ActionsSection;
