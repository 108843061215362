import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';
import {useMemoOne} from 'use-memo-one';
import {Case} from '../../../../server-api/model';
import {GenericSorter} from '../../../../state/genericSorter';
import {Spinner} from '../../../common/spinner/Spinner';
import {SettlementsTable} from '../../common/SettlementsTable';
import {CaseSettlementsState} from './state';

interface CaseSettlementsProps {
    onDataLoaded?: () => void;
    caze: Case;
}

export const CaseSettlement: FC<CaseSettlementsProps> = observer(
    ({caze, onDataLoaded}) => {
        const PS = useMemoOne(() => new CaseSettlementsState(caze.oneStepId), []);

        const [sorter, setSorter] = useState(
            new GenericSorter(PS.settlements, 'settlementCreated', false)
        );

        useEffect(() => {
            setSorter(new GenericSorter(PS.settlements, 'settlementCreated', false));
        }, [PS.settlements]);

        return (
            <>
                {!PS.loadingSettlements ? (
                    PS.settlements && PS.settlements.length > 0 ? (
                        <>
                            <h2 id='settlementsLabel'>Settlements (this case)</h2>
                            <SettlementsTable
                                sorter={sorter}
                                labelledBy='settlementsLabel'
                            />
                        </>
                    ) : null
                ) : (
                    <div style={{height: '200px', position: 'relative'}}>
                        <Spinner centered={true}/>
                    </div>
                )}
            </>
        );
    }
);
