import { runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { REGEXPS } from '../../../config';
import { modalContext } from '../../../state/modalState';
import { updateUrlSubject } from '../../../state/rxjs';
import { searchContext } from '../../../state/search/searchState';
import { useKeys } from '../../../utils/hooks';
import { Button } from '../../common/button/Button';
import { InputDate } from '../../common/input/InputDate';
import { Input } from '../../common/input/Input';
import { InputSelect } from '../../common/input/InputSelect';
import { InputSelectMulti } from '../../common/input/InputSelectMulti';
import { TextField } from '../../common/input/TextField';
import { RadioOption } from '../../common/radio/Radio';
import { RadioGroup } from '../../common/radio/RadioGroup';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import './Filters.css';
import validate from 'validate.js';
import { clearTitleHistory } from 'utils/title';
import { __RouterContext } from 'react-router';
import { SEARCH } from 'routes';
import { appContext } from 'state/appState';

const booleanOptions = { Yes: true, No: false };

export const Filters: FC<ModalProps> = observer(({ showModal, closeModal }) => {
  const searchState = useContext(searchContext);
  const { filters } = searchState;
  const modalState = useContext(modalContext);
  const routerState = useContext(__RouterContext);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const appState = useContext(appContext);
  const allDateFiltersHidden = appState.portalConfig.hideFiltersDatesWarrant &&
    appState.portalConfig.hideFiltersDatesOffence &&
    appState.portalConfig.hideFiltersDatesDue &&
    appState.portalConfig.hideFiltersDatesReferred;

  const changeActiveDropdown = (active: boolean) => {
    setActiveDropdown(active);
  }

  useEffect(() => {
    if (filters.caseStatus.closed === undefined) {
      runInAction(() => {
        filters.caseStatus.closed = 'open';
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const radioOptions: RadioOption[] = useMemo(
    () => [
      { label: 'Client reference', value: 'clientRef' },
      { label: 'Surname', value: 'surname' },
      { label: 'Postcode', value: 'postcode' },
      { label: 'VRM', value: 'vrm' },
    ],
    []
  );

  const submit = useCallback(async () => {
    const valid = await searchState.submitSearchFiltered();
    if (valid) {
      if (routerState.location.pathname !== SEARCH) {
        clearTitleHistory(); // hack: do not revert window title after modal is closed when submit search from dashboard
      }
      closeModal();
    }
  }, [closeModal, searchState]);

  const handleLoadClick = () => {
    modalState.toggleModal('loadSearch', true);
  };

  const handleSaveClick = () => {
    modalState.toggleModal('saveSearch', true);
  };

  useEffect(() => {
    if (searchState.filters.caseStatus.closed === 'closed') {
      runInAction(() => {
        set(searchState, 'filterOptionStatuses', {
          Cancelled: 'C',
          Successful: 'S',
        });
        if (
          searchState.filters.caseStatus.status === 'L' ||
          searchState.filters.caseStatus.status === 'A'
        ) {
          searchState.filters.caseStatus.status = undefined;
        }
      });
    } else if (searchState.filters.caseStatus.closed === 'open') {
      runInAction(() => {
        set(searchState, 'filterOptionStatuses', {
          Arrangement: 'A',
          Live: 'L',
        });
        if (
          searchState.filters.caseStatus.status === 'C' ||
          searchState.filters.caseStatus.status === 'S'
        ) {
          searchState.filters.caseStatus.status = undefined;
        }
      });
    } else {
      runInAction(() => {
        set(searchState, 'filterOptionStatuses', {
          Arrangement: 'A',
          Live: 'L',
          Cancelled: 'C',
          Successful: 'S',
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, filters.caseStatus.closed]);

  // TODO: change Enter on other shortcut as it clashes with other keyboard controls
  //useKeys(['Enter'], [submit], !showModal, undefined, undefined, undefined, activeDropdown);

  return (
    <>
      <div className='flex-group'>
        <div className='left-container'>
          <div className='flex-group'>
            <div role='group' aria-labelledby='caseDetailsLabel'>
              <h3 id='caseDetailsLabel'>Case details</h3>
              <Input
                width='grow'
                label='Client reference'
                placeholder='PCN/LO Number'
                name='clientRef'
                onChange={(e) => {
                  runInAction(() => {
                    filters.caseDetails.clientRef =
                      e.currentTarget.value.trim();
                  });
                }}
                value={filters.caseDetails.clientRef}
              />
              <Input
                width='grow'
                label='Summons #'
                placeholder='Summons number'
                name='summonsNo'
                onChange={(e) => {
                  runInAction(() => {
                    filters.caseDetails.summonsNo =
                      e.currentTarget.value.trim();
                  });
                }}
                value={filters.caseDetails.summonsNo}
              />
              <Input
                width='grow'
                label='VRM'
                placeholder='No spaces (e.g. LL114GF)'
                name='vrm'
                onChange={(e) => {
                  runInAction(() => {
                    filters.caseDetails.vrm = e.currentTarget.value.trim();
                  });
                }}
                value={filters.caseDetails.vrm}
              />
              <Input
                width='grow'
                label='Batch'
                placeholder={window.COMPANY_LABEL + ' Batch ID'}
                name='batch'
                onChange={(e) => {
                  runInAction(() => {
                    filters.caseDetails.batch = e.currentTarget.value.trim();
                  });
                }}
                value={filters.caseDetails.batch}
              />
              <Input
                width='grow'
                label={window.COMPANY_LABEL + ' reference'}
                placeholder={window.COMPANY_LABEL + ' case reference'}
                name='jbwreference'
                onChange={(e) => {
                  runInAction(() => {
                    filters.caseDetails.jbwRef = e.currentTarget.value.trim();
                  });
                }}
                value={filters.caseDetails.jbwRef}
              />
              {appState.portalConfig.hideFiltersClaimant ? null : (
                <Input
                  width='grow'
                  label='Claimant name'
                  placeholder={'Claimant name'}
                  name='claimantName'
                  onChange={(e) => {
                    runInAction(() => {
                      filters.caseDetails.claimantName = e.currentTarget.value.trim();
                    });
                  }}
                  value={filters.caseDetails.claimantName}
                />
              )}
            </div>
            <div role='group' aria-labelledby='caseStatusLabel'>
              <h3 id='caseStatusLabel'>Case status</h3>
              <InputSelect
                width='narrow'
                label='Open/Closed'
                name='openclosed'
                className='narrow'
                onChange={(val) => {
                  runInAction(() => {
                    filters.caseStatus.closed = val;
                  });
                }}
                options={{
                  ...searchState.filtersOpenClosed,
                }}
                placeholder=''
                selectedThing={filters.caseStatus.closed || 'open'}
                onToggle={changeActiveDropdown}
              />
              <InputSelect
                label='Status'
                name='status'
                options={{
                  ...searchState.filterOptionStatuses,
                  '\xA0': undefined,
                }}
                onChange={(val) => {
                  runInAction(() => {
                    filters.caseStatus.status = val;
                  });
                }}
                selectedThing={filters.caseStatus.status}
                onToggle={changeActiveDropdown}
              />
              <InputSelect
                label='Stage'
                name='stage'
                onChange={(val) => {
                  runInAction(() => {
                    filters.caseStatus.stage = val;
                  });
                }}
                options={{
                  ...searchState.filterOptionStages,
                  '\xA0': undefined,
                }}
                selectedThing={filters.caseStatus.stage}
                onToggle={changeActiveDropdown}
              />
              <InputSelect
                width='narrow'
                name='onHold'
                label='On hold'
                className='narrow'
                options={{
                  ...searchState.filterOptionHolds,
                  '\xA0': undefined,
                }}
                selectedThing={filters.caseStatus.onHold}
                onChange={(val) => {
                  runInAction(() => {
                    filters.caseStatus.onHold = val;
                  });
                }}
                onToggle={changeActiveDropdown}
              />
              <InputSelect
                width='narrow'
                label='Active agent'
                name='activeAgent'
                options={{ ...booleanOptions, '\xA0': undefined }}
                className='narrow'
                onChange={(val) => {
                  runInAction(() => {
                    filters.caseStatus.activeAgent = val;
                  });
                }}
                selectedThing={filters.caseStatus.activeAgent}
                onToggle={changeActiveDropdown}
              />
              <InputSelect
                width='narrow'
                label='Controlled goods'
                name='cgaInPlace'
                options={{ ...booleanOptions, '\xA0': undefined }}
                className='narrow'
                onChange={(val) => {
                  runInAction(() => {
                    filters.caseStatus.cga = val;
                  });
                }}
                selectedThing={filters.caseStatus.cga}
                onToggle={changeActiveDropdown}
              />
            </div>
          </div>
          {allDateFiltersHidden ? null : (
            <div role='group' aria-labelledby='datesLabel'>
              <h3 id='datesLabel'>Dates</h3>
              <div>
                {appState.portalConfig.hideFiltersDatesWarrant ? null : (
                  <div className='flex-row date-row'>
                    <InputDate
                      label='Warrant/LO date'
                      name='warrantDateFrom'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.warrantFrom = date;
                        });
                      }}
                      value={parseDate(filters.dates.warrantFrom)}
                      onToggle={changeActiveDropdown}
                    />
                    <InputDate
                      label='to'
                      name='warrantDateTo'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.warrantTo = date;
                        });
                      }}
                      value={parseDate(filters.dates.warrantTo)}
                      onToggle={changeActiveDropdown}
                    />
                  </div>
                )}
                {appState.portalConfig.hideFiltersDatesOffence ? null : (
                  <div className='flex-row date-row'>
                    <InputDate
                      label='Offence date/from'
                      name='offenceDateFrom'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.offenceFrom = date;
                        });
                      }}
                      value={parseDate(filters.dates.offenceFrom)}
                      onToggle={changeActiveDropdown}
                    />
                    <InputDate
                      label='to'
                      name='offenceDateTo'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.offenceTo = date;
                        });
                      }}
                      value={parseDate(filters.dates.offenceTo)}
                      onToggle={changeActiveDropdown}
                    />
                  </div>
                )}
                {appState.portalConfig.hideFiltersDatesDue ? null : (
                  <div className='flex-row date-row'>
                    <InputDate
                      label='Due/up to'
                      name='dueFrom'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.dueFrom = date;
                        });
                      }}
                      value={parseDate(filters.dates.dueFrom)}
                      onToggle={changeActiveDropdown}
                    />
                    <InputDate
                      label='to'
                      name='dueTo'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.dueTo = date;
                        });
                      }}
                      value={parseDate(filters.dates.dueTo)}
                      onToggle={changeActiveDropdown}
                    />
                  </div>
                )}
                {appState.portalConfig.hideFiltersDatesReferred ? null : (
                  <div className='flex-row date-row'>
                    <InputDate
                      label={'Referred to ' + window.COMPANY_LABEL + ''}
                      name='referredFrom'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.referredFrom = date;
                        });
                      }}
                      value={parseDate(filters.dates.referredFrom)}
                      onToggle={changeActiveDropdown}
                    />
                    <InputDate
                      label='to'
                      name='referredTo'
                      onChange={(date) => {
                        runInAction(() => {
                          filters.dates.referredTo = date;
                        });
                      }}
                      value={parseDate(filters.dates.referredTo)}
                      onToggle={changeActiveDropdown}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='right-container'>
          <div role='group' aria-labelledby='customerDetailsLabel'>
            <h3 id='customerDetailsLabel'>Customer details</h3>
            <Input
              width='grow'
              label='Last name'
              placeholder="Customer's last name or trading name if company"
              name='lastName'
              onChange={(e) => {
                runInAction(() => {
                  filters.customerDetails.lastName =
                    e.currentTarget.value.trim();
                });
              }}
              value={filters.customerDetails.lastName}
            />
            <Input
              width='grow'
              label='First name'
              placeholder="Customer's first name"
              name='firstName'
              onChange={(e) => {
                runInAction(() => {
                  filters.customerDetails.firstName =
                    e.currentTarget.value.trim();
                });
              }}
              value={filters.customerDetails.firstName}
            />
            <Input
              width='grow'
              label='Postcode'
              placeholder='Full postcode without spaces (e.g. L13HG)'
              name='postcode'
              className='input-postcode'
              formName={
                filters.customerDetails.postcode?.length ? 'Filters' : undefined
              }
              validateFunc={() =>
                filters.customerDetails.postcode &&
                filters.customerDetails.postcode.includes('*')
                  ? undefined
                  : validate.single(filters.customerDetails.postcode, {
                      format: {
                        pattern: REGEXPS.POSTCODE,
                        message: 'Please enter a UK postcode',
                      },
                    })
              }
              onChange={(e) => {
                runInAction(() => {
                  filters.customerDetails.postcode = e.currentTarget.value
                    .trim()
                    .toUpperCase();
                });
              }}
              value={filters.customerDetails.postcode}
            />
            <div className='flex-row flex-50'>
              <InputSelect
                width='grow'
                label='Vulnerable flag'
                name='vulnerableFlag'
                options={{ ...booleanOptions, '\xA0': undefined }}
                className='narrow'
                onChange={(val) => {
                  runInAction(() => {
                    filters.customerDetails.vulnerable = val;
                  });
                }}
                selectedThing={filters.customerDetails.vulnerable}
                onToggle={changeActiveDropdown}
              />
              <InputSelect
                width='grow'
                label='Warning flag'
                name='warningFlag'
                options={{ ...booleanOptions, '\xA0': undefined }}
                className='narrow'
                onChange={(val) => {
                  runInAction(() => {
                    filters.customerDetails.warning = val;
                  });
                }}
                selectedThing={filters.customerDetails.warning}
                onToggle={changeActiveDropdown}
              />
            </div>
            <div className='flex-row flex-50'>
              <InputSelect
                width='grow'
                label='Is company'
                name='isCompany'
                options={{ ...booleanOptions, '\xA0': undefined }}
                className='narrow'
                onChange={(val) => {
                  runInAction(() => {
                    filters.customerDetails.isCompany = val;
                  });
                }}
                selectedThing={filters.customerDetails.isCompany}
                onToggle={changeActiveDropdown}
              />

              <InputSelect
                width='grow'
                label='Has broken arrangements'
                name='arrangementsBroken'
                className='narrow'
                options={{ ...booleanOptions, '\xA0': undefined }}
                onChange={(val) => {
                  runInAction(() => {
                    filters.customerDetails.brokenArrangement = val;
                  });
                }}
                selectedThing={filters.customerDetails.brokenArrangement}
                onToggle={changeActiveDropdown}
              />
            </div>

            <div className='flex-row cases-counts-row'>
              <Input
                width='short'
                label='Open cases count'
                placeholder='0'
                name='liveCasesCountFrom'
                options={{
                  numeralPositiveOnly: true,
                  numericOnly: true,
                  numeralDecimalScale: 0,
                }}
                onChange={(e) => {
                  runInAction(() => {
                    filters.customerDetails.liveCasesCountFrom = isNaN(
                      parseInt(e.currentTarget.value)
                    )
                      ? undefined
                      : parseInt(e.currentTarget.value);
                  });
                }}
                value={filters.customerDetails.liveCasesCountFrom}
              />
              <Input
                width='short'
                label='to'
                placeholder='any'
                options={{
                  numeralPositiveOnly: true,
                  numericOnly: true,
                  numeralDecimalScale: 0,
                }}
                name='liveCasesCountTo'
                onChange={(e) => {
                  runInAction(() => {
                    filters.customerDetails.liveCasesCountTo = isNaN(
                      parseInt(e.currentTarget.value)
                    )
                      ? undefined
                      : parseInt(e.currentTarget.value);
                  });
                }}
                value={filters.customerDetails.liveCasesCountTo}
              />
            </div>
          </div>
          {allDateFiltersHidden ? null : (
            <div className='contract-section'>
              <h3 id='contractFilterHeading'>Contract</h3>
              {searchState.gettingContracts ? (
                <Spinner small={true} block={true} />
              ) : (
                <InputSelectMulti
                  properties={{ 'aria-labelledby': 'contractFilterHeading' }}
                  width='block'
                  name='contract'
                  options={{
                    ...searchState.filterOptionContracts,
                  }}
                  checkedKeys={filters.contracts || []}
                  onChange={(keys) =>
                    runInAction(() => {
                      filters.contracts = keys;
                    })
                  }
                  onToggle={changeActiveDropdown}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {allDateFiltersHidden ? (
        <div className='contract-section'>
          <h3 id='contractFilterHeading'>Contract</h3>
          {searchState.gettingContracts ? (
            <Spinner small={true} block={true} />
          ) : (
            <InputSelectMulti
              properties={{ 'aria-labelledby': 'contractFilterHeading' }}
              width='block'
              name='contract'
              options={{
                ...searchState.filterOptionContracts,
              }}
              checkedKeys={filters.contracts || []}
              onChange={(keys) =>
                runInAction(() => {
                  filters.contracts = keys;
                })
              }
              onToggle={changeActiveDropdown}
            />
          )}
        </div>
      ) : null}
      <div className='paste-box-wrapper'>
        <h3 id='pasteAndSearchLabel'>Paste and search</h3>
        <div className='flex-group'>
          <div className='radios'>
            <RadioGroup
              properties={{'aria-labelledby' : 'pasteAndSearchLabel'}}
              name='pasteRadios'
              options={radioOptions}
              value={filters.pasteType || undefined}
              onChange={(val) => {
                runInAction(() => {
                  filters.pasteType = val;
                });
              }}
            />
          </div>
          <div className='paste-box'>
            <TextField
              name='pasteArea'
              placeholder='Copy and paste a list of values from excel or csv file'
              value={filters.pasteText}
              properties={{ style: { resize: 'none' } }}
              onChange={(e) => {
                runInAction(() => {
                  filters.pasteText = e.currentTarget.value;
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className='modal-actions'>
        <Button onClick={closeModal} className='btn btn-gray'>
          Close
        </Button>
        <Button onClick={handleLoadClick} className='btn btn-primary'>
          Load search
        </Button>
        <Button onClick={handleSaveClick} className='btn btn-primary'>
          Save search
        </Button>
        <Button onClick={submit} className='btn btn-success'>
          Run
        </Button>
      </div>
    </>
  );
});

const stringifyDate = (date: Date | null, to?: true) => {
  if (!date) {
    return undefined;
  }
  if (to) {
    return moment(date).add(1, 'day').subtract(1, 'millisecond').valueOf();
  } else {
    return moment(date).startOf('day').valueOf();
  }
};

const parseDate = (value?: number | null) => {
  // if (!value) {
  //   return undefined;
  // }
  // if (typeof value === "number") {
  //   return moment(value).toDate();
  // }
  // if (typeof value === "string" && !isNaN(parseInt(value))) {
  //   return moment(parseInt(value)).toDate();
  // }
  if (value) {
    return value;
  }
  return undefined;
};
