import { DIVISION } from '../config';
import {
  addGetParams,
  get,
  handleResponse,
  handleResponseStream,
  post,
  upload,
} from './communications';
import {
  Case,
  Categories,
  DashboardSummary,
  DebtorDetailsResponse,
  ExchangeFile,
  HistoryData,
  LoginResponse,
  Payment,
  PortalUser,
  ProfileData,
  ReportParam,
  SearchEntryResponse,
  ServerResponse,
  Statement,
  USER_ROLE,
  LetterCase,
  SearchCaseEntryResponse,
  BookmarksResponse,
  ExchangeFileCategory,
  CourtAppointmentRequest,
  CourtAppointment,
  Settlement,
  PortalConfig
} from './model';

export const login = (
  login: string,
  password: string,
  authCode?: string
): Promise<ServerResponse<LoginResponse>> => {
  return handleResponse(
    post('/api/portal/login', { login, password, authCode })
  );
};

export const logout = (): Promise<any> => {
  return handleResponse(get('/rest/logout'));
};

export const getUserInfo = (): Promise<ServerResponse<ProfileData>> => {
  return handleResponse(get('/rest/user/read/profile'));
};

export const updateUserInfo = (
  login: string,
  newUserInfo: NewUserInfoParams | any
): Promise<ServerResponse<any>> => {
  return handleResponse(
    post(addGetParams('/rest/user/update/profile', { login }), newUserInfo)
  );
};

export const resetPassword = (login: string): Promise<ServerResponse<any>> => {
  return handleResponse(get('/api/resetpassword', { login }));
};

export const resetPasswordCompletion = (
  token: string,
  password: string
): Promise<ServerResponse<any>> => {
  return handleResponse(
    get('/api/resetpassword/completion', { password, token })
  );
};

export const resetUserPassword = (
  login: string
): Promise<ServerResponse<any>> => {
  return handleResponse(get('/rest/manageuser/resetpassword', { login }));
};

export const checkLoginValidity = (
  login: string
): Promise<ServerResponse<any>> => {
  return handleResponse(get('/rest/user/exist', { login }));
};

export const changePassword = (
  login: string,
  newPassword: string
): Promise<ServerResponse<any>> => {
  return handleResponse(post('/rest/changepassword', { login, newPassword }));
};

export const requestSearchDebtors = (
  searchString: string,
  firstResult: number,
  maxResults: number,
  sortProperty?: string,
  desc?: boolean,
  filters?: { [name: string]: any }
): Promise<ServerResponse<SearchEntryResponse[]>> => {
  return handleResponse(
    post(
      addGetParams('/rest/debtors', {
        searchString,
        firstResult,
        maxResults,
      }),
      { sortProperty, desc, ...filters }
    )
  );
};

export const requestSearchCases = (
  searchString: string,
  firstResult: number,
  maxResults: number,
  sortProperty?: string,
  desc?: boolean,
  filters?: { [name: string]: any }
): Promise<ServerResponse<SearchCaseEntryResponse[]>> => {
  return handleResponse(
    post(
      addGetParams('/rest/cases', { searchString, firstResult, maxResults }),
      { sortProperty, desc, ...filters }
    )
  );
};

export const requestSearchCasesCount = (
  searchString: string,
  filters?: { [name: string]: any }
): Promise<ServerResponse<any>> => {
  return handleResponse(
    post(
      addGetParams('/rest/cases-count', {
        searchString,
      }),
      { ...filters }
    )
  );
};

export const requestSearchDebtorsCount = (
  searchString: string,
  filters?: { [name: string]: any }
): Promise<ServerResponse<any>> => {
  return handleResponse(
    post(
      addGetParams('/rest/debtors-count', {
        searchString,
      }),
      { ...filters }
    )
  );
};

export const requestCasesByIds = (
  casesIds: string[],
  firstResult: number,
  maxResults: number
): Promise<ServerResponse<Case[]>> => {
  return handleResponse(
    post(addGetParams('/rest/case/select', { firstResult, maxResults }), {
      casesIds,
    })
  );
};

export const requestDebtorsByCasesIds = (
  casesIds: string[],
  firstResult: number,
  maxResults: number,
  sortProperty?: string,
  desc?: boolean
): Promise<ServerResponse<SearchEntryResponse[]>> => {
  return handleResponse(
    post(
      addGetParams('/rest/debtor/select-by-cases', {
        firstResult,
        maxResult: maxResults,
      }),
      {
        list: casesIds,
        sortProperty,
        desc,
      }
    )
  );
};

export const requestDebtorsByIds = (
  list: string[],
  firstResult: number,
  maxResults: number
): Promise<ServerResponse<SearchEntryResponse[]>> => {
  return handleResponse(
    post(addGetParams('/rest/debtor/select', { firstResult, maxResults }), {
      list,
    })
  );
};

export const getDebtorDetails = (
  debtorId: string,
  closedCases: boolean
): Promise<ServerResponse<DebtorDetailsResponse>> => {
  return handleResponse(get('/rest/debtor/details', { debtorId, closedCases }));
};

export const getDebtorArrangement = (
  debtorId: string
): Promise<ServerResponse<any>> => {
  return handleResponse(get('/rest/debtor/arrangement', { debtorId }));
};

export const getCourtAppointments = (
  debtorId: string
): Promise<ServerResponse<CourtAppointment[]>> => {
  return handleResponse(get('/rest/debtor/court/appointments', { debtorId }));
};
export const getCourtAppointmentsCount = (
  debtorId: string
): Promise<ServerResponse<any>> => {
  return handleResponse(
    get('/rest/debtor/court/appointments/count', { debtorId })
  );
};
export const getCourtAppointmentsStatuses = (
  debtorId: string
): Promise<ServerResponse<{ [value: string]: string }>> => {
  return handleResponse(get('/rest/debtor/court/appointments/statuses'));
};
export const updateAppointment = (
  appointmentRequest: CourtAppointmentRequest
): Promise<ServerResponse<any>> => {
  return handleResponse(
    post('/rest/actions/update-appointment', appointmentRequest)
  );
};
export const getDebtorAllPayments = (
  debtorId: string,
  firstResult: number,
  maxResult: number,
  closedCases: boolean
): Promise<ServerResponse<Payment[]>> => {
  return handleResponse(
    get('/rest/debtor/payments', {
      debtorId,
      firstResult,
      maxResult,
      closedCases,
    })
  );
};

export const getDebtorAllPaymentsCount = (
  debtorId: string,
  closedCases: boolean
): Promise<ServerResponse<{ paymentCount: number }>> => {
  return handleResponse(
    get('/rest/debtor/payments/count', {
      debtorId,
      closedCases,
    })
  );
};

export const getDebtorStatement = (
  debtorId: string
): Promise<ServerResponse<Statement>> => {
  return handleResponse(get('/rest/debtor/statement', { debtorId }));
};

export const getCaseRemittance = (
  oneStepId: number
): Promise<
  ServerResponse<{
    balanceToClient: number;
    balanceToUs: number;
    remittanceToClient: number;
    remittanceToUs: number;
    totalToClient: number;
    totalToUs: number;
    waitingToClient: number;
    waitingToUs: number;
  }>
> => {
  return handleResponse(get('/rest/case/remittance', { oneStepId }));
};

export const getCaseDetails = (
  oneStepId: string
): Promise<ServerResponse<any>> => {
  return handleResponse(get('/rest/case/details', { oneStepId }));
};

export const getCaseStatement = (
  oneStepId: string
): Promise<ServerResponse<Statement>> => {
  return handleResponse(get('/rest/case/statement', { oneStepId }));
};

export const getCasePayments = (
  oneStepId: string,
  firstResult: number,
  maxResult: number
): Promise<ServerResponse<Payment[]>> => {
  return handleResponse(
    get('/rest/case/payments', { oneStepId, firstResult, maxResult })
  );
};

export const getCasePaymentsCount = (
  oneStepId: string
): Promise<ServerResponse<{ paymentCount: number }>> => {
  return handleResponse(get('/rest/case/payments/count', { oneStepId }));
};

export const getCaseSettlements = (
    oneStepId: string
): Promise<ServerResponse<Settlement[]>> => {
    return handleResponse(
        get('/rest/case/settlements', { oneStepId })
    );
};

export const getLettersAll = (
  debtorId: string,
  closedCases: boolean
): Promise<ServerResponse<any>> => {
  return handleResponse(
    get('/rest/letters-and-files/letters/all', { debtorId, closedCases })
  );
};

export const getLettersCases = (
  debtorId: string,
  closedCases: boolean
): Promise<ServerResponse<Array<LetterCase>>> => {
  return handleResponse(
    get('/rest/letters-and-files/cases', { debtorId, closedCases })
  );
};

export const getFiles = (
  debtorId: string,
  closedCases: boolean
): Promise<ServerResponse<any>> => {
  return handleResponse(
    get('/rest/letters-and-files/files/all', { debtorId, closedCases })
  );
};

export const getSelectedFiles = (
  debtorId: string,
  casesOsIds: string[]
): Promise<ServerResponse<any>> => {
  return handleResponse(
    post('/rest/letters-and-files/files/select', {
      casesOsIds,
      debtorId,
    })
  );
};

export const getSelectedLetters = (
  casesOsIds: string[]
): Promise<ServerResponse<any>> => {
  return handleResponse(
    post('/rest/letters-and-files/letters/select', { casesOsIds })
  );
};

export const printLetter = (letterId: string): Promise<ServerResponse<any>> => {
  return handleResponse(
    get('/rest/letters-and-files/letters/print', { letterId })
  );
};

export const downloadFile = (f: string, s: string): Promise<File> => {
  return handleResponseStream(get('/api/download', { f, s }));
};

export const uploadFile = (formData: FormData): Promise<any> => {
  return handleResponse(upload('/rest/upload', formData));
};

export const getDashboardCases = (): Promise<
  ServerResponse<DashboardSummary[]>
> => {
  return handleResponse(get('/rest/load-dashboard-cases'));
};

export const getCaseFilesContracts = (
  opened: boolean
): Promise<ServerResponse<Categories>> => {
  return handleResponse(
    get('/rest/dashboard/file/contract-options', { opened })
  );
};

export const getCaseFilesTypes = (): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/dashboard/file/types'));
};

export const uploadCaseFile = (formData: FormData): Promise<any> => {
  return handleResponse(upload('/rest/dashboard/file/upload', formData));
};

export const getReturnCategories = (
    caseId: string
): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/load-return-reasons', { caseId }));
};

export const getPaymentCategories = (): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/load-payment-types'));
};

export const getHoldCategories = (
  debtorId: string
): Promise<ServerResponse<Array<{ description: string; id: string }>>> => {
  return handleResponse(get('/rest/load-hold-categories', { debtorId }));
};

export const isEnablePlaceOnHold = (
  contractsOpenStepIds: number[]
): Promise<ServerResponse<boolean>> => {
  return handleResponse(post('/rest/is-enable-place-on-hold', { contractsOpenStepIds }));
};

export const getContactDetailsCategories = (): Promise<
  ServerResponse<Categories>
> => {
  return handleResponse(get('/rest/load-contact-details-types'));
};

export const getEnquiryCategories = (): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/load-portal-enquiry-types'));
};

export const getNoteCategories = (): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/load-note-types'));
};

export const getFileCategories = (): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/letters-and-files/files/debtor-file-types'));
};

export const getHistory = (
  debtorId: string,
  closedCases: boolean,
  firstResult: number,
  maxResults: number,
  highlightMode: boolean,
  categories?: string[],
  cases?: number[],
  customerNotes?: boolean,
  sortProperty?: string,
  desc?: boolean
): Promise<ServerResponse<HistoryData>> => {
  return handleResponse(
    post(
      addGetParams('/rest/history', {
        debtorId,
        closedCases,
        firstResult,
        maxResults,
        highlightMode,
      }),
      {
        categories,
        cases,
        sortProperty,
        desc,
        customerNotes,
      }
    )
  );
};

// ********  actions  *********** //
export const actionPlaceOnHold = (
  holdCategoryId: string,
  expiresDate: Date,
  comment: string,
  casesIds: string[]
): Promise<ServerResponse<ActionResponse>> => {
  return handleResponse(
    post('/rest/actions/place-on-hold', {
      holdCategoryId,
      expiresDate,
      comment,
      casesIds,
    })
  );
};

export const actionTakeOffHold = (
  comment: string,
  casesIds: string[]
): Promise<ServerResponse<ActionResponse>> => {
  return handleResponse(
    post('/rest/actions/take-off-hold', { comment, casesIds })
  );
};

export const actionRequestReturn = (
  comment: string,
  returnReasonId: string,
  casesIds: string[]
): Promise<ServerResponse<ActionResponse>> => {
  return handleResponse(
    post('/rest/actions/request-return', { comment, returnReasonId, casesIds })
  );
};

export const getDebtFeeElementsToChange = (caseId: string): Promise<ServerResponse<Categories>> => {
    return handleResponse(get('/rest/load-debt-fee-elements-to-adjust', {caseId}));
};

export const getDebtAdjustTypes = (caseId: string): Promise<ServerResponse<Categories>> => {
    return handleResponse(get('/rest/load-debt-adjust-types', {caseId}));
};

export const actionChangeDebt = (
  comment: string,
  newAmount: string,
  caseId: string,
  feeElementId?: string
): Promise<ServerResponse<ActionResponse>> => {
  return handleResponse(
    post('/rest/actions/change-debt-amount', { comment, newAmount, caseId, feeElementId })
  );
};

export const actionAddPayment = (
    amount: string,
    paymentTypeId: number,
    casesIds: string[],
    receiveDate: number | null,
): Promise<ServerResponse<ActionResponse>> => {
    return handleResponse(
        post('/rest/actions/add-direct-payment', {
            amount,
            paymentTypeId,
            casesIds,
            receiveDate
        })
    );
};

export const actionAddNote = (
  noteText: string,
  noteTypeId: string,
  casesIds?: string[],
  debtorId?: string
): Promise<ServerResponse<ActionResponse>> => {
  return handleResponse(
    post('/rest/actions/add-note', { noteText, noteTypeId, casesIds, debtorId })
  );
};

export const actionAddContactDetails = (
  params: ContactDetailsParams
): Promise<ServerResponse<ActionResponse>> => {
  return handleResponse(post('/rest/actions/add-contact-details', params));
};

export const actionSendMessage = (
  params: SendMessageParams
): Promise<ServerResponse<any>> => {
  return handleResponse(post('/rest/actions/message-jbw', params));
};

export const getExchangeFilesList = (): Promise<
  ServerResponse<ExchangeFile[]>
> => {
  return handleResponse(get('/rest/exchange-files/list'));
};

export const getExchangeFilesListFiltered = (
  dateFrom?: number,
  dateTo?: number,
  types?: string[]
): Promise<ServerResponse<ExchangeFile[]>> => {
  return handleResponse(
    post('/rest/exchange-files/list/filter', {
      dateFrom,
      dateTo,
      types,
    })
  );
};

export const getExchangeFileTypes = (): Promise<
  ServerResponse<ExchangeFileCategory[]>
> => {
  return handleResponse(get('/rest/exchange-files/file-types'));
};

export const downloadExchangeFile = (fileId: string): Promise<File> => {
  return handleResponseStream(
    get('/rest/exchange-files/download', { fileDescriptorId: fileId })
  );
};

export const getReportsList = (): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/report/list'));
};

export const getReportParams = (
  reportId: string
): Promise<ServerResponse<ReportParam[]>> => {
  return handleResponse(get('/rest/report/params', { reportId }));
};

export const getReportClients = (): Promise<ServerResponse<Categories>> => {
  return handleResponse(get('/rest/report/client'));
};

export const getReportFile = (reportId: string, params: {}): Promise<File> => {
  return handleResponseStream(post('/rest/report/run', { reportId, params }));
};

export const getUsers = (
  includeInactive: boolean
): Promise<ServerResponse<PortalUser[]>> => {
  return handleResponse(
    get('/rest/user/search', { include_inactive: includeInactive })
  );
};

export const createUser = (newUser: NewUser): Promise<any> => {
  return handleResponse(post(addGetParams('/rest/user/create'), newUser));
};

export const updateUser = (
  id: string,
  updatedUser: UpdatedUser
): Promise<any> => {
  return handleResponseStream(
    post(addGetParams('/rest/user/update', { id }), updatedUser)
  );
};

export const updateUserRole = (
  userIds: string[],
  role: USER_ROLE
): Promise<any> => {
  return handleResponseStream(
    post(addGetParams('/rest/user/update/role', { role }), userIds)
  );
};

export const updateUserStatus = (
  userIds: string[],
  status: boolean
): Promise<any> => {
  return handleResponseStream(
    post(
      addGetParams('/rest/user/update/status', {
        active: status,
      }),
      userIds
    )
  );
};

export const updateUserTwoFactor = (
  userIds: string[],
  enable: boolean
): Promise<any> => {
  return handleResponseStream(
    post(
      addGetParams('/rest/user/update/2fa', {
        enable,
      }),
      userIds
    )
  );
};

export const setupTwoFactor = (): Promise<
  ServerResponse<{
    first: {
      key: string;
      verificationCode: string;
    };
    second: string;
  }>
> => {
  return handleResponse(get('/rest/user/setup/2fa'));
};

export const getDivision = (): Promise<ServerResponse<DIVISION>> => {
  return handleResponse(get('/api/portal/division'));
};

export const getBookmarksAndSearches = (): Promise<
  ServerResponse<BookmarksResponse>
> => {
  return handleResponse(get('/rest/bookmarks-and-searches'));
};

export const addBookmark = (
  bookmarkTypeId: 100 | 200,
  debtorId?: string,
  caseId?: string
): Promise<ServerResponse<BookmarksResponse>> => {
  return handleResponse(
    post('/rest/bookmarks-and-searches/bookmark', {
      debtorId,
      caseId,
      bookmarkTypeId,
    })
  );
};

export const deleteBookmark = (
  bookmarkId: string
): Promise<ServerResponse<BookmarksResponse>> => {
  return handleResponse(
    get(
      '/rest/bookmarks-and-searches/bookmark',
      { bookmarkId },
      { method: 'DELETE' }
    )
  );
};

export const addSearch = (
  searchString: string,
  name: string
): Promise<ServerResponse<BookmarksResponse>> => {
  return handleResponse(
    post('/rest/bookmarks-and-searches/savedsearch', {
      searchString,
      name,
    })
  );
};

export const deleteSearch = (
  searchId: string
): Promise<ServerResponse<BookmarksResponse>> => {
  return handleResponse(
    get(
      '/rest/bookmarks-and-searches/savedsearch',
      { searchId },
      { method: 'DELETE' }
    )
  );
};

export const requestPortalConfig = (): Promise<
  ServerResponse<PortalConfig>
> => {
  return handleResponse(get('/rest/portal-config'));
};

export interface NewUser {
  login: string;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  active: boolean;
  role: string;
  contractGroups: { name: string; id: string }[];
  twoFactorEnabled?: boolean;
  twoFactorConfigured?: boolean;
}

export interface UpdatedUser {
  login?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  email?: string;
  active?: boolean;
  role?: string;
  batchEmail?: boolean;
  fileUploadedEmail?: boolean;
  contractGroups: { name: string; id: string }[];
  twoFactorEnabled?: boolean;
  twoFactorConfigured?: boolean;
}
export interface ActionResponse {
  isPositive: boolean;
  isNegative: boolean;
  causeIndex: number;
}

export interface CasesParams {
  closedCases?: boolean;
  debtorIds?: string[];
  caseStatus?: string;
  isOnHold?: boolean;
  isVulnerable?: boolean;
  caseStatusCga?: boolean;
  sortProperty?: string;
  desc?: boolean;
}

export interface ContactDetailsParams {
  debtorId: string;
  newAddress: {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    line6: string;
    postcode: string;
  };
  otherContactDetails: {
    type: number;
    value: string;
  };
  isWarning?: boolean;
  isVulnerable?: boolean;
}

export interface SendMessageParams {
  enquiryTypeId: number;
  subject: string;
  content: string;
  casesIds?: string[];
  debtorId?: string;
}

export interface NewUserInfoParams {
  firstName: string | null;
  lastName: string | null;
  position: string | null;
  email: string | null;
}
